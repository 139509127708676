import { createSlice } from '@reduxjs/toolkit';
import { Category, InCategory } from '../../schema/category';
import { License, HasLicense } from '../../schema/license';
import {
  Organization,
  Lot,
  LotIsAssociatedWith,
} from '../../schema/lot';
import { Tag, IsTagged } from '../../schema/tag';
import { createAppAsyncThunk } from '../createAppAsyncThunk';
import { Auction, Bid, Consume, Rate, Rating, Win } from '../../schema/auction';

export interface LotState {
  initialized: boolean;
  wins: Win[];
  consumes: Consume[];
  bids: Bid[];
  rates: Rate[];
  ratings: Rating[];
  auctions: Auction[];
  lots: Lot[];
  organizations: Organization[];
  lotAssociations: LotIsAssociatedWith[];
  categories: Category[];
  topLevelCategories: Category[];
  licenses: License[];
  tags: Tag[];
  isTagged: IsTagged[];
  inCategory: InCategory[];
  hasLicense: HasLicense[];
}

const initialState: LotState = {
  initialized: false,
  wins: [],
  bids: [],
  rates: [],
  ratings: [],
  consumes: [],
  auctions: [],
  lots: [],
  organizations: [],
  lotAssociations: [],
  categories: [],
  topLevelCategories: [],
  licenses: [],
  tags: [],
  isTagged: [],
  inCategory: [],
  hasLicense: [],
};

//call bidAsync then verifyBidAsync in sequence
export const bidAndVerifyAsync = createAppAsyncThunk(
  'lot/bidAndVerify',
  async (bidData: { auction_id: string; amount: number }, thunkAPI) => {
    await thunkAPI.dispatch(bidAsync(bidData));
    await thunkAPI.dispatch(verifyBidAsync({auction_id: bidData.auction_id}));
    await thunkAPI.dispatch(fetchWinForAuctionAsync({auction_id: bidData.auction_id}));
    await thunkAPI.dispatch(fetchAuctionAsync({auction_id: bidData.auction_id}));
  }
);

export const bidAsync = createAppAsyncThunk(
  'lot/bid',
  async (bidData: { auction_id: string; amount: number }, thunkAPI) => {
    const result = await thunkAPI.extra.db.bid(bidData.auction_id, bidData.amount);
    return result;
  }
);

export const verifyBidAsync = createAppAsyncThunk(
  'lot/verifyBid',
  async (bidData: { auction_id: string }, thunkAPI) => {
    const bid = await thunkAPI.extra.db.verifyBid(bidData.auction_id);
    return bid;
  }
);

export const fetchWinForAuctionAsync = createAppAsyncThunk(
  'lot/fetchWinForAuction',
  async (auctionData: { auction_id: string }, thunkAPI) => {
    const win = await thunkAPI.extra.db.getWinForAuction(auctionData.auction_id);
    return win;
  }
);

export const fetchAuctionAsync = createAppAsyncThunk(
  'lot/fetchAuction',
  async (auctionData: { auction_id: string }, thunkAPI) => {
    const auction = await thunkAPI.extra.db.getAuction(auctionData.auction_id);
    return auction;
  }
);

export const consumeAsync = createAppAsyncThunk(
  'lot/consume',
  async (consumeData: { lot_id: string, job_type: string, template_name: string, win_id: string; encodedRequest: string }, thunkAPI) => {
    const consume = await thunkAPI.extra.db.consume(consumeData.lot_id,
      consumeData.job_type, consumeData.template_name, consumeData.win_id, consumeData.encodedRequest);
    return consume;
  }
);

// load all data in parallel
export const fetchAllLotDataAsync = createAppAsyncThunk(
  'lot/loadData',
  async (userData: { id: string }, thunkAPI) => {
    await Promise.all([
      thunkAPI.dispatch(fetchWinsForUserAsync({id: userData.id})),
      thunkAPI.dispatch(fetchBidsAsync()),
      thunkAPI.dispatch(fetchRatesAsync()),
      thunkAPI.dispatch(fetchRatingsAsync()),
      thunkAPI.dispatch(fetchAuctionsAsync()),
      thunkAPI.dispatch(fetchConsumesAsync()),
      thunkAPI.dispatch(fetchLotsAsync()),
      thunkAPI.dispatch(fetchCategoriesAsync()),
      thunkAPI.dispatch(fetchLotAssociationsAsync()),
      thunkAPI.dispatch(fetchTopLevelCategoriesAsync()),
      thunkAPI.dispatch(fetchOrganizationsAsync()),
      thunkAPI.dispatch(fetchLicensesAsync()),
      thunkAPI.dispatch(fetchTagsAsync()),
      thunkAPI.dispatch(fetchIsTaggedAsync()),
      thunkAPI.dispatch(fetchInCategoryAsync()),
      thunkAPI.dispatch(fetchHasLicenseAsync()),
    ]);
  }
);

export const fetchWinsForUserAsync = createAppAsyncThunk(
  'lot/fetchWinsForUser',
  async (userData: { id: string }, thunkAPI) => {
    const wins = await thunkAPI.extra.db.getWinsForUser(userData.id);
    return wins;
  }
);

export const fetchBidsAsync = createAppAsyncThunk(
  'lot/fetchBids',
  async (_, thunkAPI) => {
    const bids = await thunkAPI.extra.db.getBids();
    return bids;
  }
);

export const fetchRatesAsync = createAppAsyncThunk(
  'lot/fetchRates',
  async (_, thunkAPI) => {
    const rates = await thunkAPI.extra.db.getRates();
    return rates;
  }
);

export const fetchRatingsAsync = createAppAsyncThunk(
  'lot/fetchRatings',
  async (_, thunkAPI) => {
    const ratings = await thunkAPI.extra.db.getRatings();
    return ratings;
  }
);

export const fetchAuctionsAsync = createAppAsyncThunk(
  'lot/fetchOpenAuctions',
  async (_, thunkAPI) => {
    const openAuctions = await thunkAPI.extra.db.getAuctions();
    return openAuctions;
  }
);

export const fetchConsumesAsync = createAppAsyncThunk(
  'lot/fetchConsumes',
  async (_, thunkAPI) => {
    const consumes = await thunkAPI.extra.db.getConsumes();
    return consumes;
  }
);

export const fetchLotsAsync = createAppAsyncThunk(
  'lot/fetchLots',
  async (_, thunkAPI) => {
    const lots = await thunkAPI.extra.db.getLots();
    return lots;
  }
);

export const fetchOrganizationsAsync = createAppAsyncThunk(
  'lot/fetchOrganizations',
  async (_, thunkAPI) => {
    const organizations = await thunkAPI.extra.db.getOrganizations();
    return organizations;
  }
);

export const fetchLotAssociationsAsync = createAppAsyncThunk(
  'lot/fetchLotAssociations',
  async (_, thunkAPI) => {
    const lotAssociations = await thunkAPI.extra.db.getLotOrganizations();
    return lotAssociations;
  }
);

export const fetchCategoriesAsync = createAppAsyncThunk(
  'lot/fetchCategories',
  async (_, thunkAPI) => {
    const categories = await thunkAPI.extra.db.getCategories();
    return categories;
  }
);

export const fetchTopLevelCategoriesAsync = createAppAsyncThunk(
  'lot/fetchTopLevelCategories',
  async (_, thunkAPI) => {
    const topLevelCategories = await thunkAPI.extra.db.getTopLevelCategories();
    return topLevelCategories;
  }
);

export const fetchLicensesAsync = createAppAsyncThunk(
  'lot/fetchLicenses',
  async (_, thunkAPI) => {
    const licenses = await thunkAPI.extra.db.getLicenses();
    return licenses;
  }
);

export const fetchTagsAsync = createAppAsyncThunk(
  'lot/fetchTags',
  async (_, thunkAPI) => {
    const tags = await thunkAPI.extra.db.getTags();
    return tags;
  }
);

export const fetchIsTaggedAsync = createAppAsyncThunk(
  'lot/fetchIsTagged',
  async (_, thunkAPI) => {
    const tagged = await thunkAPI.extra.db.getIsTagged();
    return tagged;
  }
);

export const fetchInCategoryAsync = createAppAsyncThunk(
  'lot/fetchInCategory',
  async (_, thunkAPI) => {
    const subcategorized = await thunkAPI.extra.db.getInCategory();
    return subcategorized;
  }
);

export const fetchHasLicenseAsync = createAppAsyncThunk(
  'lot/fetchHasLicense',
  async (_, thunkAPI) => {
    const licensed = await thunkAPI.extra.db.getHasLicense();
    return licensed;
  }
);

export const listenToConsumeUpdates = createAppAsyncThunk(
  'auth/listenToConsumeUpdates',
  async ( winData: { id: string; }, thunkAPI) => {
    const db = thunkAPI.extra.db;
    const query_id = await db.subscribeToConsume(winData.id, (x) => {
			if (x.action === 'UPDATE') {
        thunkAPI.dispatch(updateConsumeData(x.result));
      }
		});
    console.log('listenToConsumeUpdates', query_id);
    return query_id;
  }
);

export const killMessageStream = createAppAsyncThunk(
  'auth/killMessageStream',
  async (query_id: string, thunkAPI) => {
    const db = thunkAPI.extra.db;
    await db.kill(query_id);
    console.log('killMessageStream', query_id);
  }
);

export const lotSlice = createSlice({
  name: 'lot',
  initialState,
  reducers: {
    resetState: (state) => {
      state.initialized = false;
      state.wins = [];
      state.bids = [];
      state.rates = [];
      state.auctions = [];
      state.lots = [];
      state.organizations = [];
      state.lotAssociations = [];
      state.categories = [];
      state.topLevelCategories = [];
      state.licenses = [];
      state.tags = [];
      state.isTagged = [];
      state.inCategory = [];
      state.hasLicense = [];
    },
    updateConsumeData: (state, action) => {
      const consume = action.payload;
      const index = state.consumes.findIndex((c) => c.id === consume.id);
      if (index === -1) {
        state.consumes.push(consume);
      } else {
        state.consumes[index] = consume;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLotDataAsync.fulfilled, (state) => {
        state.initialized = true;
      })
      .addCase(fetchWinsForUserAsync.fulfilled, (state, action) => {
        state.wins = action.payload;
      })
      .addCase(fetchBidsAsync.fulfilled, (state, action) => {
        state.bids = action.payload;
      })
      .addCase(verifyBidAsync.fulfilled, (state, action) => {
        const bid = action.payload;
        const index = state.bids.findIndex((b) => b.id === bid.id);
        if (index === -1) {
          state.bids.push(bid);
        } else {
          state.bids[index] = bid;
        }
      })
      .addCase(fetchWinForAuctionAsync.fulfilled, (state, action) => {
        const win = action.payload;
        if (win !== undefined && win.id) {
          const index = state.wins.findIndex((w) => w.id === win.id);
          if (index === -1) {
            state.wins.push(win);
          } else {
            state.wins[index] = win;
          }
        }
      })
      .addCase(fetchAuctionAsync.fulfilled, (state, action) => {
        const auction = action.payload;
        if (auction !== undefined && auction.id) {
          const index = state.auctions.findIndex((a) => a.id === auction.id);
          if (index === -1) {
            state.auctions.push(auction);
          } else {
            state.auctions[index] = auction;
          }
        }
      })
      .addCase(consumeAsync.fulfilled, (state, action) => {
        const consume = action.payload;
        if (consume !== undefined && consume.id) {
          const index = state.consumes.findIndex((c) => c.id === consume.id);
          if (index === -1) {
            state.consumes.push(consume);
          } else {
            state.consumes[index] = consume;
          }
        }
      })
      .addCase(fetchRatesAsync.fulfilled, (state, action) => {
        state.rates = action.payload;
      })
      .addCase(fetchRatingsAsync.fulfilled, (state, action) => {
        state.ratings = action.payload;
      })
      .addCase(fetchAuctionsAsync.fulfilled, (state, action) => {
        state.auctions = action.payload;
      })
      .addCase(fetchConsumesAsync.fulfilled, (state, action) => {
        state.consumes = action.payload;
      })
      .addCase(fetchLotsAsync.fulfilled, (state, action) => {
        state.lots = action.payload;
      })
      .addCase(fetchLotAssociationsAsync.fulfilled, (state, action) => {
        state.lotAssociations = action.payload;
      })
      .addCase(fetchOrganizationsAsync.fulfilled, (state, action) => {
        state.organizations = action.payload;
      })
      .addCase(fetchCategoriesAsync.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(fetchTopLevelCategoriesAsync.fulfilled, (state, action) => {
        state.topLevelCategories = action.payload;
      })
      .addCase(fetchLicensesAsync.fulfilled, (state, action) => {
        state.licenses = action.payload;
      })
      .addCase(fetchTagsAsync.fulfilled, (state, action) => {
        state.tags = action.payload;
      })
      .addCase(fetchIsTaggedAsync.fulfilled, (state, action) => {
        state.isTagged = action.payload;
      })
      .addCase(fetchInCategoryAsync.fulfilled, (state, action) => {
        state.inCategory = action.payload;
      })
      .addCase(fetchHasLicenseAsync.fulfilled, (state, action) => {
        state.hasLicense = action.payload;
      });
  },
});

export const { resetState, updateConsumeData } = lotSlice.actions;

export type LotSlice = {
  [lotSlice.name]: ReturnType<(typeof lotSlice)['reducer']>;
};

export const selectLots = (state: { lot: LotState }) =>
  state.lot.lots;
export const selectLotById =
  (id: string) => (state: { lot: LotState }) =>
    state.lot.lots.find((lot) => lot.id === id);
export const selectOrganizations = (state: { lot: LotState }) =>
  state.lot.organizations;
export const selectOrganizationById =
  (id: string) => (state: { lot: LotState }) =>
    state.lot.organizations.find((organization) => organization.id === id);
export const selectOrganizationByLotId =
  (id: string) => (state: { lot: LotState }) => {
    const lotAssociation = state.lot.lotAssociations.find((lotAssociation) => lotAssociation.in === id);
    return state.lot.organizations.find((organization) => organization.id === lotAssociation?.out);
  };
export const selectLotAssociations = (state: { lot: LotState }) =>
  state.lot.lotAssociations;
export const selectCategories = (state: { lot: LotState }) =>
  state.lot.categories;
export const selectLicenses = (state: { lot: LotState }) =>
  state.lot.licenses;
export const selectTags = (state: { lot: LotState }) => state.lot.tags;
export const selectIsTagged = (state: { lot: LotState }) =>
  state.lot.isTagged;
export const selectTagsByLotId = (id: string) => (state: { lot: LotState }) => {
  const tagged = state.lot.isTagged.filter((tag) => tag.in === id);
  return state.lot.tags.filter((tag) => tagged.some((t) => t.out === tag.id));
};
export const selectInCategory = (state: { lot: LotState }) =>
  state.lot.inCategory;
export const selectCategoryByLotId = (id: string) => (state: { lot: LotState }) => {
  const inCategory = state.lot.inCategory.filter((inCategory) => inCategory.in === id);
  const categories = state.lot.categories.filter((category) => inCategory.find((s) => s.out === category.id));
  return categories[0];
}
export const selectLicensed = (state: { lot: LotState }) =>
  state.lot.hasLicense;
export const selectLicenseByLotId = (id: string) => (state: { lot: LotState }) => {
  const licensed = state.lot.hasLicense.filter((hasLicense) => hasLicense.in === id);
  const licenses = state.lot.licenses.filter((license) => licensed.find((l) => l.out === license.id));
  return licenses[0];
}
export const selectAuctionById = (id: string) => (state: { lot: LotState }) =>
  state.lot.auctions.find((auction) => auction.id === id);
export const selectAuctions = (state: { lot: LotState }) =>
  state.lot.auctions;
export const selectAuctionsByLotId = (id: string) => (state: { lot: LotState }) =>
  state.lot.auctions.filter((auction) => auction.listing === id);
export const selectWins = (state: { lot: LotState }) => state.lot.wins;
export const selectWinByAuctionId = (id: string) => (state: { lot: LotState }) =>
  state.lot.wins.find((win) => win.out === id);
export const selectConsumesByWinId = (id: string) => (state: { lot: LotState }) =>
  state.lot.consumes.filter((consume) => consume.auction_ref === id);
export const selectRates = (state: { lot: LotState }) => state.lot.rates;
export const selectRateByAuctionId = (id: string) => (state: { lot: LotState }) => {
  const rates = state.lot.rates.filter((rate) => rate.auction === id);
  return rates[0];
}
export const selectRatingsByLotId = (id: string) => (state: { lot: LotState }) =>
  state.lot.ratings.filter((rating) => rating.lot === id);
export const selectBids = (state: { lot: LotState }) => state.lot.bids;