import { Surreal } from 'surrealdb.js';
import { LiveQueryResponse, RawQueryResult } from 'surrealdb.js/script/types';
import { Auction, Bid, Consume, Rate, Rating, Win } from '../schema/auction';
import { Category, InCategory } from '../schema/category';
import { HasLicense, License } from '../schema/license';
import { Lot, LotIsAssociatedWith, Organization } from '../schema/lot';
import { Module, ModuleIsAssociatedWith, ModuleOrganization } from '../schema/module';
import { Node, NodeIsAssociatedWith, NodeOrganization } from '../schema/node';
import { IsTagged, Tag } from '../schema/tag';
import { User, UserDisplay } from '../schema/user';

export const endpoint = process.env.REACT_APP_DB_ENDPOINT ?? 'ws://127.0.0.1:3001/rpc';
export const namespace = process.env.REACT_APP_DB_NS ?? 'algovera';
export const database = process.env.REACT_APP_DB_DB ?? 'algovera';

class Database extends Surreal {
  async init() {
    console.log('Connecting to database...');
    try {
      await this.connect(endpoint, { namespace, database });
      console.log('Connected to database...');
    } catch (e) {
      console.error(`Failed to connect to database: ${e}`);
    }
  }

  signinDB = async (
    username: string,
    password: string
  ): Promise<string | undefined> => {
    const token = await this.signin({
      namespace,
      database,
      scope: 'user',
      username,
      password,
    });
    return token;
  };

  authenticateDB = async (token: string): Promise<string | undefined> => {
    await this.authenticate(token);
    return token;
  };

  getSignedInUser = async (): Promise<User | undefined> => {
    const result: RawQueryResult[][] = await this.query('select * from $auth');
    const user = await User.parseAsync(result[0][0]);
    return user;
  };

  signupDB = async (
    name: string,
    username: string,
    password: string,
    invite: string
  ): Promise<string | undefined> => {
    const token = await this.signup({
      namespace,
      database,
      scope: 'user',
      name,
      username,
      password,
      invite,
    });
    return token;
  };

  doesUsernameExist = async (username: string): Promise<boolean> => {
    const result: RawQueryResult[] = await this.query(
      'select username from user where username = $username;',
      {
        username,
      }
    );
    return !!(
      result[0] &&
      (result[0] as [RawQueryResult])[0] &&
      (result[0] as [User])[0].username
    );
  };

  isInviteCodeValid = async (inviteCode: string): Promise<boolean> => {
    const result: RawQueryResult[] = await this.query(
      'select * from invite_code where code = $inviteCode;',
      {
        inviteCode,
      }
    );
    return !!(
      result[0] &&
      (result[0] as [RawQueryResult])[0]
    );
  }

  invalidateUser = async (): Promise<boolean> => {
    await this.invalidate();
    return true;
  };

  getUsersDisplay = async (): Promise<UserDisplay[]> => {
    const usersDisplayResult: RawQueryResult[] = await this.query(
      'select id, name from user;'
    );
    const usersDisplay: UserDisplay[] = [];
    for (const userDisplayResult of usersDisplayResult[0] as [RawQueryResult]) {
      const userDisplay = await UserDisplay.parseAsync(userDisplayResult);
      usersDisplay.push(userDisplay);
    }
    return usersDisplay;
  };

  getLots = async (): Promise<Lot[]> => {
    const lots = await this.select<Lot>('lot');
    return lots;
  };

  getModules = async (): Promise<Module[]> => {
    const modules = await this.select<Module>('module');
    return modules;
  };

  getNodes = async (): Promise<Node[]> => {
    const nodes = await this.select<Node>('node');
    return nodes;
  };

  getOrganizations = async (): Promise<Organization[]> => {
    const organizations = await this.select<Organization>('organization');
    return organizations;
  };

  getModuleOrganizations = async (): Promise<ModuleIsAssociatedWith[]> => {
    const moduleIsAssociatedWithResult = await this.query(
      'SELECT * FROM is_associated_with WHERE type::is::record(in, "module");'
    );
    const associations: ModuleIsAssociatedWith[] = [];
    for (const associationResult of moduleIsAssociatedWithResult[0] as [
      RawQueryResult,
    ]) {
      const moduleIsAssociatedWith =
        await ModuleIsAssociatedWith.parseAsync(associationResult);
      associations.push(moduleIsAssociatedWith);
    }
    return associations;
  };

  getNodeOrganizations = async (): Promise<NodeIsAssociatedWith[]> => {
    const nodeIsAssociatedWithResult = await this.query(
      'SELECT * FROM is_associated_with WHERE type::is::record(in, "node");'
    );
    const associations: NodeIsAssociatedWith[] = [];
    for (const associationResult of nodeIsAssociatedWithResult[0] as [
      RawQueryResult,
    ]) {
      const nodeIsAssociatedWith =
        await NodeIsAssociatedWith.parseAsync(associationResult);
      associations.push(nodeIsAssociatedWith);
    }
    return associations;
  };

  getLotOrganizations = async (): Promise<LotIsAssociatedWith[]> => {
    const lotIsAssociatedWithResult = await this.query(
      'SELECT * FROM is_associated_with WHERE type::is::record(in, "lot");'
    );
    const associations: LotIsAssociatedWith[] = [];
    for (const associationResult of lotIsAssociatedWithResult[0] as [
      RawQueryResult,
    ]) {
      const lotIsAssociatedWith =
        await LotIsAssociatedWith.parseAsync(associationResult);
      associations.push(lotIsAssociatedWith);
    }
    return associations;
  };

  getCategories = async (): Promise<Category[]> => {
    const categories = await this.select<Category>('category');
    return categories;
  };

  getTopLevelCategories = async (): Promise<Category[]> => {
    const topLevelCategoriesResult = await this.query(
      'SELECT * FROM category WHERE parent_categories=NONE;'
    );
    const topLevelCategories: Category[] = [];
    for (const topLevelCategoryResult of topLevelCategoriesResult[0] as [
      RawQueryResult,
    ]) {
      const topLevelCategory = await Category.parseAsync(
        topLevelCategoryResult
      );
      topLevelCategories.push(topLevelCategory);
    }
    return topLevelCategories;
  };

  getLicenses = async (): Promise<License[]> => {
    const licenses = await this.select<License>('license');
    return licenses;
  };

  getTags = async (): Promise<Tag[]> => {
    const tags = await this.select<Tag>('tag');
    return tags;
  };

  getIsTagged = async (): Promise<IsTagged[]> => {
    const isTagged = await this.select<IsTagged>('is_tagged');
    return isTagged;
  };

  getInCategory = async (): Promise<InCategory[]> => {
    const inCategory = await this.select<InCategory>('in_category');
    return inCategory;
  };

  getHasLicense = async (): Promise<HasLicense[]> => {
    const hasLicense = await this.select<HasLicense>('has_license');
    return hasLicense;
  };

  getWinsForUser = async (user_id: string): Promise<Win[]> => {
    const winsResult = await this.query(
      'SELECT * FROM wins WHERE in=$user;',
      { user: user_id }
    );
    const wins: Win[] = [];
    for (const winResult of winsResult[0] as [RawQueryResult]) {
      const win = await Win.parseAsync(winResult);
      wins.push(win);
    }
    return wins;
  };

  getBids = async (): Promise<Bid[]> => {
    const bids = await this.select<Bid>('bids');
    return bids;
  };

  bid = async (auction: string, amount: number): Promise<boolean> => {
    const bidResult = await this.query(
      'RELATE $auth->requests_to_bid_on->$auction SET amount=$amount;',
      {
        auction: auction,
        amount: amount,
      }
    );
    // FIXME: test bidResult
    return true;
  };

  verifyBid = async (auction: string): Promise<Bid> => {
    const verifyBidResult = await this.query(
      'SELECT * FROM bids WHERE in=$auth AND out=$auction',
      {
        auction: auction,
      }
    );
    const bid = await Bid.parseAsync(
      (verifyBidResult[0] as [RawQueryResult])[0]
    );
    return bid;
  };

  getRates = async (): Promise<Rate[]> => {
    const rates = await this.select<Rate>('rates');
    return rates;
  };

  getRatings = async (): Promise<Rating[]> => {
    const ratings = await this.select<Rating>('rating');
    return ratings;
  }

  getAuctions = async (): Promise<Auction[]> => {
    const auctionsResult: RawQueryResult[] = await this.query(
      'SELECT * FROM auction;'
    );
    const auctions: Auction[] = [];
    for (const auctionResult of auctionsResult[0] as [RawQueryResult]) {
      const auction = await Auction.parseAsync(auctionResult);
      auctions.push(auction);
    }
    return auctions;
  };

  getAuction = async (auction_id: string): Promise<Auction | undefined> => {
    console.log(auction_id);
    const auctionResult = await this.query(
      'SELECT * FROM auction WHERE id=$auction;',
      { auction: auction_id }
    );
    console.log(auctionResult);
    if (!(auctionResult[0] as [RawQueryResult])[0]) {
      return undefined;
    }
    const auction = await Auction.parseAsync(
      (auctionResult[0] as [RawQueryResult])[0]
    );
    console.log(auction);
    return auction;
  };

  getWinForAuction = async (auction_id: string): Promise<Win | undefined> => {
    const winResult = await this.query(
      'SELECT * FROM wins WHERE out=$auction;',
      { auction: auction_id }
    );
    const win = await Win.parseAsync((winResult[0] as [RawQueryResult])[0]);
    return win;
  };

  getOpenAuctionForAuction = async (
    auction_id: string
  ): Promise<Auction | undefined> => {
    const openAuctionResult = await this.query(
      'SELECT * FROM auction WHERE hammer_time>time::now() AND id=$auction;',
      { auction: auction_id }
    );
    if (!(openAuctionResult[0] as [RawQueryResult])[0]) {
      return undefined;
    }
    const openAuction = await Auction.parseAsync(
      (openAuctionResult[0] as [RawQueryResult])[0]
    );
    return openAuction;
  };

  getConsumes = async (): Promise<Consume[]> => {
    const consumes = await this.select<Consume>('consume');
    return consumes;
  }

  consume = async (lot_id: string, job_type: string, template_name: string, win_id: string, encodedRequest: string) => {
    const consumeResult = await this.create('consume', {
      'auction_ref': win_id,
      'lot': lot_id,
      'job_type': job_type,
      'request': {'encoded': encodedRequest},
      'template_params': {
        'template_name': template_name,
        'template_args': {},
      },
    });
    const consume = await Consume.parseAsync((consumeResult[0]));
    return consume;
  };

  subscribeToConsume = async (win_id: string,
    cb: (data: LiveQueryResponse<Consume>) => void) => {
      const result = await this.query(
        `LIVE SELECT * FROM consume WHERE auction_ref == ${win_id};`
      );
      const query_id: string = result[0] as string;
      await this.listenLive(query_id, cb);
      return query_id;
  }

}
export default Database;
