import React from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import { Typography, useTheme } from '@mui/material';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { selectAuth } from '../features/auth/authSlice';
import { useProSidebar } from 'react-pro-sidebar';
import ReorderIcon from '@mui/icons-material/Reorder';

export function SideNav() {
  const auth = useAppSelector(selectAuth);
  const theme = useTheme();
  const location = useLocation();

  const { collapseSidebar, toggleSidebar, broken } = useProSidebar();

  return (
    <Sidebar
      style={styles.sideNav}
      breakPoint='md'
      backgroundColor={theme.palette.primary.main}
    >
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              color: active
                ? theme.palette.primary.contrastText
                : theme.palette.primary.contrastText,
              backgroundColor: active
                ? theme.palette.primary.main
                : theme.palette.primary.main,
            };
          },
        }}
      >
        <MenuItem
          onClick={() => (broken ? toggleSidebar() : collapseSidebar())}
          icon={<ReorderIcon />}
        ></MenuItem>
        {auth.authenticated && (
          <MenuItem
            active={location.pathname === '/dashboard'}
            component={<Link to='/dashboard' />}
            icon={<DashboardOutlinedIcon />}
          >
            <Typography variant='body2'>Dashboard</Typography>
          </MenuItem>
        )}
      </Menu>
    </Sidebar>
  );
}

/**
 * @type {import("@mui/material").SxProps}
 */
const styles = {
  sideNav: {
    height: '100%',
    top: 'auto',
  },
};
