import z from 'zod';
import { record } from './zodrecord';

export const User = z.object({
  id: record('user'),
  name: z.string(),
  username: z.string().toLowerCase(),
  created: z.coerce.date().transform((date) => date.toISOString()),
});
export type User = z.infer<typeof User>;

export const UserDisplay = z.object({
  id: record('user'),
  name: z.string(),
});
export type UserDisplay = z.infer<typeof UserDisplay>;
