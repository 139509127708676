import z from 'zod';
import { record } from './zodrecord';

export const Lot = z.object({
  id: record('lot'),
  name: z.string(),
  description: z.string(),
  author: record('user'),
  request: z.string(),
  reply: z.string(),
  created: z.coerce.date().transform((date) => date.toISOString()),
  updated: z.coerce.date().transform((date) => date.toISOString()),
});
export type Lot = z.infer<typeof Lot>;

export const Organization = z.object({
  id: record('organization'),
  name: z.string(),
  image: z.string(),
  owner: record('user'),
});
export type Organization = z.infer<typeof Organization>;

// FIXME: move organization back as a property of lot
export const LotIsAssociatedWith = z.object({
  id: record('is_associated_with'),
  in: record('lot'),
  out: record('organization'),
});
export type LotIsAssociatedWith = z.infer<typeof LotIsAssociatedWith>;

export const UserIsAssociatedWith = z.object({
  id: record('is_associated_with'),
  in: record('user'),
  out: record('organization'),
});
export type UserIsAssociatedWith = z.infer<typeof UserIsAssociatedWith>;
