import React from 'react';
import { Link, List, ListItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import {
  selectNodeAssociations,
  selectNodes,
  selectOrganizations,
} from './nodeSlice';

export function NodeList() {
  const nodes = useAppSelector(selectNodes);
  const organizations = useAppSelector(selectOrganizations);
  const allNodeAssociations = useAppSelector(selectNodeAssociations);

  return (
    <List>
      {nodes.map((node) => {
        const nodeAssociation = allNodeAssociations.find(
          (nodeAssociation) => nodeAssociation.in === node.id
        );
        const organizationDetails = organizations.find(
          (organization) => nodeAssociation?.out === organization.id
        );
        return (
          <Link key={node.id} component={RouterLink} to={`/node/${node.id}`} color='text.secondary'>
            <ListItem>
              {node.id}
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
}
