import z from 'zod';
import { record } from './zodrecord';

export const Category = z.object({
  id: record('category'),
  name: z.string(),
  //FIXME: parent categories
});
export type Category = z.infer<typeof Category>;

export const InCategory = z.object({
  id: record('in_category'),
  in: record('lot'),
  out: record('category'),
});

export type InCategory = z.infer<typeof InCategory>;
