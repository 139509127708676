import React from 'react';
import { Link, List, ListItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import {
  selectLotAssociations,
  selectLots,
  selectOrganizations,
} from './lotSlice';

export function LotList() {
  const lots = useAppSelector(selectLots);
  const organizations = useAppSelector(selectOrganizations);
  const allLotAssociations = useAppSelector(selectLotAssociations);

  return (
    <List>
      {lots.map((lot) => {
        const lotAssociation = allLotAssociations.find(
          (lotAssociation) => lotAssociation.in === lot.id
        );
        const organizationDetails = organizations.find(
          (organization) => lotAssociation?.out === organization.id
        );
        return (
          <Link key={lot.id} component={RouterLink} to={`/task/${lot.id}`} color='text.secondary'>
            <ListItem>
              {lot.author.split(":")[1]}/{lot.name}
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
}
