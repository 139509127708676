import React from 'react';
import { Link, List, ListItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import {
  selectModuleAssociations,
  selectModules,
  selectOrganizations,
} from './moduleSlice';

export function ModuleList() {
  const modules = useAppSelector(selectModules);
  const organizations = useAppSelector(selectOrganizations);
  const allModuleAssociations = useAppSelector(selectModuleAssociations);

  return (
    <List>
      {modules.map((module) => {
        const moduleAssociation = allModuleAssociations.find(
          (moduleAssociation) => moduleAssociation.in === module.id
        );
        const organizationDetails = organizations.find(
          (organization) => moduleAssociation?.out === organization.id
        );
        return (
          <Link key={module.id} component={RouterLink} to={`/module/${module.id}`} color='text.secondary'>
            <ListItem>
              {module.author.split(":")[1]}/{module.name}
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
}
