import React, { useEffect, useState } from 'react';
import { selectUsers } from '../features/auth/authSlice';
import { useAppSelector } from '../app/hooks';
import { UserDisplay } from '../schema/user';

function UserName({ user_id }: { user_id: string }) {
  const users = useAppSelector(selectUsers);
  const [user, setUser] = useState<UserDisplay | null>(null);

  useEffect(() => {
    if (users) {
      const foundUser = users.find((u) => u.id === user_id);
      setUser(foundUser ?? null);
    }
  }, [users, user_id]);

  return (
    <span>{user?.name ?? ''}</span>
  );
}

export default UserName;
