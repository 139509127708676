import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DynamicForm from '../../components/DynamicForm';
import { Time } from '../../components/TimeAgo';
import UserName from '../../components/UserName';
import { selectUser } from '../auth/authSlice';
import LotComponent from './LotComponent';
import {
  bidAndVerifyAsync,
  fetchAllLotDataAsync,
  selectAuctionById,
  selectCategoryByLotId,
  selectConsumesByWinId,
  selectLicenseByLotId,
  selectLotById,
  selectOrganizationByLotId,
  selectRateByAuctionId,
  selectTagsByLotId,
  selectWinByAuctionId,
  listenToConsumeUpdates,
  killMessageStream,
} from './lotSlice';
import protobuf from 'protobufjs';
import { Close, ThumbDownSharp, ThumbUpSharp } from '@mui/icons-material';
import CustomSnackbar, { SnackbarConfig } from '../../components/CustomSnackbar';

const Schema = z.object({
  auction_id: z.string().min(1, 'Please select an auction'),
  bid_amount: z
    .string()
    .refine((value) => !isNaN(parseFloat(value)), {
      message: 'Invalid input: Please enter a number',
    })
    .refine((value) => parseFloat(value) >= 0.01, {
      message: 'Please enter a bid amount greater than .01',
    }),
});

type FormSchema = z.infer<typeof Schema>;

export function Auction() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormSchema>({
    resolver: zodResolver(Schema),
  });
  const { auctionid } = useParams();
  const auction = useAppSelector(selectAuctionById(auctionid ?? ''));
  const lot = useAppSelector(selectLotById(auction?.listing ?? ''));
  const win = useAppSelector(selectWinByAuctionId(auction?.id ?? ''));
  const consumes = useAppSelector(selectConsumesByWinId(win?.id ?? ''));
  const { initialized } = useAppSelector((state) => state.lot);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const queryIdRef = useRef<string | null>(null);

  const hammerTime = new Date(auction?.hammer_time ?? '');
  const now = new Date();
  const isOpen = hammerTime > now;

  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarConfig>({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (!initialized && user) {
      dispatch(fetchAllLotDataAsync({ id: user?.id ?? '' }));
    }
    const fetchData = async () => {
      if (!win) {
        return;
      }
      const consumeResult = await dispatch(listenToConsumeUpdates({ id: win.id}));
      if (listenToConsumeUpdates.fulfilled.match(consumeResult)) {
        if (consumeResult.payload) {
          const oldQueryId = queryIdRef.current; // Store the current queryId
          if (oldQueryId) {
              dispatch(killMessageStream(oldQueryId)); // Kill the previous message stream if it exists
              console.log('Killing message stream', oldQueryId);
          }
          queryIdRef.current = consumeResult.payload; // Update the ref with the new queryId
          console.log('Listening to consume updates', queryIdRef.current);
      }
      } else {
        console.error('Failed to listen to consume updates');
      }
    };

    fetchData();

    return () => {
      if (queryIdRef.current) {
        console.log('Killing message stream', queryIdRef.current);
        dispatch(killMessageStream(queryIdRef.current)); // Kill the message stream on cleanup
      }
    };

  }, [user, dispatch, initialized, win]);

  const organization = useAppSelector(selectOrganizationByLotId(lot?.id ?? ''));
  // FIXME: https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
  const tags = useAppSelector(selectTagsByLotId(lot?.id ?? ''));
  const category = useAppSelector(selectCategoryByLotId(lot?.id ?? ''));
  const license = useAppSelector(selectLicenseByLotId(lot?.id ?? ''));
  const bids = useAppSelector((state) => state.lot.bids);

  const bidForThisAuction = bids.find((bid) => bid.out === auction?.id);
  const rating = useAppSelector(selectRateByAuctionId(auction?.id ?? ''))?.rating ?? null;

  const handleClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const handleAction = useCallback((message: any) => {
    setSnackbarConfig({
      open: true,
      message: message,
      actionComponent: (
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <Close />
          </IconButton>
        </React.Fragment>
      ),
    });
  }, []);

  const buyItNowHandler = useCallback(
    async (data: { auction_id: string; amount: number }) => {
      const bidResult = await dispatch(
        bidAndVerifyAsync({
          auction_id: data.auction_id,
          amount: data.amount,
        })
      );
      if (bidAndVerifyAsync.fulfilled.match(bidResult)) {
        handleAction('Buy It Now Successful!');
      }
    },
    [dispatch, handleAction]
  );


  const bidHandler = useCallback(
    async (data: FormSchema) => {
      const bidResult = await dispatch(
        bidAndVerifyAsync({
          auction_id: data.auction_id,
          amount: parseFloat(data.bid_amount),
        })
      );
      if (bidAndVerifyAsync.fulfilled.match(bidResult)) {
        handleAction('Bid Successful!');
      }
      reset();
    },
    [dispatch, bids, reset, handleAction]
  );

  // decode function that takes a base64 string, decodes it, and returns a string
  function decode(encoded: string | null, isRequest: boolean): any {
    if (encoded === '') {
      return '<empty>';
    }
    if (!encoded) {
      return '';
    }
    let buffer = new Uint8Array(encoded.length);
    const actualLength = protobuf.util.base64.decode(encoded, buffer, 0);
    buffer = buffer.slice(0, actualLength);
    let Args = null;
    if (isRequest && lot?.request) {
      const root = protobuf.parse(lot.request).root;
      Args = root.lookupType('Request');
    } else if (!isRequest && lot?.reply) {
      const root = protobuf.parse(lot.reply).root;
      Args = root.lookupType('Reply');
    }
    if (!Args) {
      return '';
    }
    const argsMessage = Args.decode(buffer);
    const args = Args.toObject(argsMessage);
    return (
      <div>
          {Args.fieldsArray.map((field : any, index : any) => (
            <span key={index}>{field.name}: {args[field.name]}&nbsp;</span>
          ))}
      </div>
    );
  }

  return (
    <>
    <Box>
      <Paper elevation={3} sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}>
        <Typography variant='h4' component='h1' gutterBottom color='primary'>
          Auction
        </Typography>
        <Typography variant='body1'>
          <strong>Consigner:</strong>{' '}
          <UserName user_id={auction?.consigner ?? ''} />
          {rating !== null && (
            rating ? 
            <ThumbUpSharp color='primary' />
            :
            <ThumbDownSharp color='error' />
          )}
        </Typography>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          {isOpen && (
            <Grid item xs={12} sm={6}>
              <Typography color='primary'>My Current Bid:</Typography>
              {bidForThisAuction ? (
                <Typography variant='h5' paragraph>
                  ${bidForThisAuction.amount}
                </Typography>
              ) : (
                <Typography variant='body1' paragraph>
                  No bid yet
                </Typography>
              )}
            </Grid>
          )}
          {!isOpen && win?.in && (
            <Grid item xs={12} sm={6}>
              <Typography color='primary'>Winner:</Typography>
              <Typography variant='body1'>
                <UserName user_id={win?.in ?? ''} />
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Typography color='secondary'>
              Auction {isOpen ? 'Ends' : 'Ended'}:
            </Typography>
            <Typography>
              {new Date(auction?.hammer_time ?? Date()).toLocaleDateString()}
              &nbsp; (<Time isoDate={auction?.hammer_time ?? ''} />)
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            {isOpen &&
              (() => {
                return (
                  <React.Fragment>
                    <form onSubmit={handleSubmit(bidHandler)}>
                      <input
                        type='hidden'
                        {...register('auction_id')}
                        value={auction?.id}
                      />
                      <Box display='flex' sx={{ verticalAlign: 'middle' }}>
                        <TextField
                          id='bid_amount'
                          label='Amount'
                          variant='outlined'
                          error={Boolean(errors.bid_amount)}
                          helperText={errors.bid_amount?.message}
                          {...register('bid_amount')}
                        />
                        <Button
                          key='bid-button'
                          variant='contained'
                          color='primary'
                          type='submit'
                        >
                          {bidForThisAuction ? 'Update Bid' : 'Bid'}
                        </Button>
                      </Box>
                    </form>
                  </React.Fragment>
                );
              })()}
          </Grid>
          <Grid item xs={12}>
            {auction?.buy_it_now_price && isOpen && (
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  buyItNowHandler({
                    auction_id: auction.id.toString(),
                    amount: auction.buy_it_now_price ?? 0,
                  });
                }}
              >
                buy it now for ${auction?.buy_it_now_price}
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>

      <LotComponent
        lot={lot}
        organization={organization}
        category={category}
        tags={tags}
        license={license}
      />

      { lot?.request && !isOpen && win?.in === user?.id &&
      <>
        <Paper elevation={3} sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}>
          <Typography variant='h5' component='h1' gutterBottom color='primary'>
            Run Job
          </Typography>
          
            <DynamicForm lot={lot} win_id={win?.id ?? ''}
              protobufString={
                lot?.request || ''
              }
            />

          {/* FIXME: run job without a form, just a button */}
        </Paper>


        <Paper elevation={3} sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}>
          <Box>
            <Card>
              <CardHeader
                title='Results'/>
              <CardContent>
                <Grid container spacing={1} alignItems={'center'}>
                  {consumes.map((consume) =>
                    <React.Fragment key={consume.id}>
                      <Grid item xs={12} md={6} key={consume.id}>
                        <Typography variant='body1' color='primary'>
                          {decode(consume.request.encoded, true)} 
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant='body2'>
                          {decode(consume.reply?.encoded ?? null, false)}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Paper>
      </>
      }
    </Box>
    <CustomSnackbar
      open={snackbarConfig.open}
      message={snackbarConfig.message}
      handleClose={handleClose}
      actionComponent={snackbarConfig.actionComponent}
    />
    </>
  );
}

/**
 * @type {import("@mui/material").SxProps}
 */

const styles = {
  sellerGrid: {
    mt: 2,
  },
};
