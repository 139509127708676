import z from 'zod';
import { record } from './zodrecord';

export const Node = z.object({
  id: record('node'),
  name: z.string(),
  description: z.string(),
  user_id: z.string(),
  num_gpus: z.string(),
  address: z.string(),
  os: z.string(),
  created: z.coerce.date().transform((date) => date.toISOString()),
  updated: z.coerce.date().transform((date) => date.toISOString()),
});
export type Node = z.infer<typeof Node>;

export const NodeOrganization = z.object({
  id: record('organization'),
  name: z.string(),
  image: z.string(),
  owner: record('user'),
});
export type NodeOrganization = z.infer<typeof NodeOrganization>;

// FIXME: move organization back as a property of node
export const NodeIsAssociatedWith = z.object({
  id: record('is_associated_with'),
  in: record('node'),
  out: record('organization'),
});
export type NodeIsAssociatedWith = z.infer<typeof NodeIsAssociatedWith>;

export const UserIsAssociatedWith = z.object({
  id: record('is_associated_with'),
  in: record('user'),
  out: record('organization'),
});
export type UserIsAssociatedWith = z.infer<typeof UserIsAssociatedWith>;
