import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectAuth, signoutAsync } from '../features/auth/authSlice';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

interface NavProps {
  toggleDarkMode: () => void;
}

const AppHeader: React.FC<NavProps> = ({ toggleDarkMode }) => {
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // memoize the function, preventing new instances on every render
  const signin = useCallback(async () => navigate('/signin'), [navigate]);

  const signout = useCallback(async () => {
    await dispatch(signoutAsync());
    navigate('/');
  }, [dispatch, navigate]);

  const { collapseSidebar, toggleSidebar, broken } = useProSidebar();

  return (
    <>
      <AppBar position='static' sx={styles.appBar}>
        <Toolbar>
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/logo.png" alt="Logo" style={{ marginRight: 10, height: '50px' }} />
          </Link>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
             
          </Typography>

          <Button color="inherit" component={Link} to="/tasks" startIcon={<TaskAltIcon />}>
            Tasks
          </Button>
          <Button color="inherit" component={Link} to="/modules" startIcon={<DashboardOutlinedIcon />}>
            Modules
          </Button>
          <Button color="inherit" component={Link} to="/nodes" startIcon={<DeviceHubIcon />}>
            Nodes
          </Button>

          {!auth.authenticated && (
            <Box>
              <Typography variant='body1' component='span'>
                <Button
                  title='Sign Up'
                  color='inherit'
                  onClick={() => navigate('/signup')}
                >
                  Sign Up
                </Button>
              </Typography>
              <Typography
                variant='body1'
                component='span'
              >
                <IconButton
                  title='Sign In'
                  color='inherit'
                  onClick={() => signin()}
                >
                  <LoginIcon />
                </IconButton>
              </Typography>
            </Box>
          )}

          {auth.authenticated && (
            <div>
              <Typography
                variant='body1'
                component='span'
                sx={{ marginRight: 2 }}
              >
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => navigate('/dashboard')}
                >
                  Buy Credits
                </Button>
              </Typography>
              <Typography
                variant='body1'
                component='span'
                sx={{ marginRight: 2 }}
              >
                ({auth?.user?.username})
                <IconButton
                  title='Sign Out'
                  // color='secondary'
                  onClick={() => signout()}
                >
                  <LogoutIcon />
                </IconButton>
              </Typography>
            </div>
          )}

          <IconButton
            title='Toggle Dark Mode'
            // color='secondary'
            onClick={() => toggleDarkMode()}
          >
            <DarkModeOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

/** @type {import("@mui/material").SxProps} */
const styles = {
    appBar: {
        bgcolor: 'neutral.main',
        marginBottom: 2,
    },  
}

export default AppHeader;
