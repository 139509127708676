import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { useAppSelector } from './app/hooks';
import AppHeader from './components/AppHeader';
import { Footer } from './components/Footer';
import { SideNav } from './components/SideNav';
import AuthenticateWithToken from './features/auth/Auth';
import { selectAuth } from './features/auth/authSlice';
import { Signin } from './features/auth/Signin';
import { Signup } from './features/auth/Signup';
import { Lot } from './features/lot/Lot';
import { Lots } from './features/lot/Lots';
import { Module } from './features/module/Module';
import { Modules } from './features/module/Modules';
import { Node } from './features/node/Node';
import { Nodes } from './features/node/Nodes';
import AboutPage from './pages/About';
import DashboardPage from './pages/Dashboard';
import HomePage from './pages/Home';
import napthaTheme from './theme';
import { Auction } from './features/lot/Auction';

function App() {
  const auth = useAppSelector(selectAuth);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(prefersDarkMode);
  const theme = napthaTheme(darkMode);
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <ProSidebarProvider>
        <CssBaseline enableColorScheme />
        <Container>
          <AuthenticateWithToken />
          <BrowserRouter>
            <AppHeader toggleDarkMode={toggleDarkMode} />
            <Box sx={styles.container}>
              <SideNav />
                <Box
                  component={'main'}
                  sx={styles.mainSection}
                >
                <Routes>
                  <Route path='/' element={<HomePage />} />
                  <Route path='/about' element={<AboutPage />} />
                  <Route path='/signin' element={<Signin />} />
                  <Route path='/signup' element={<Signup />} />
                  <Route
                    path='/dashboard'
                    element={auth.authenticated ? <DashboardPage /> : <Signin />}
                  />
                  <Route
                    path='/task/:lotid'
                    element={auth.authenticated ? <Lot /> : <Signin />}
                  />
                  <Route
                    path='/tasks'
                    element={auth.authenticated ? <Lots /> : <Signin />}
                  />
                  <Route
                    path='/module/:moduleid'
                    element={auth.authenticated ? <Module /> : <Signin />}
                  />
                  <Route
                    path='/modules'
                    element={auth.authenticated ? <Modules /> : <Signin />}
                  />
                  <Route
                    path='/node/:nodeid'
                    element={auth.authenticated ? <Node /> : <Signin />}
                  />
                  <Route
                    path='/nodes'
                    element={auth.authenticated ? <Nodes /> : <Signin />}
                  />
                  <Route
                    path='/auction/:auctionid'
                    element={auth.authenticated ? <Auction /> : <Signin />}
                  />                  
                  <Route path='*' element={<HomePage />} />
                </Routes>
              </Box>
            </Box>
          </BrowserRouter>
          <Footer />
        </Container>
      </ProSidebarProvider>
    </ThemeProvider>
  );
}

/**
 * @type {import('@mui/material').SxProps}
 */
const styles = {
  container: {
    display: 'flex',
    bgcolor: 'neutral.light',
    height: 'calc(100% - 64px)'
  },
  mainSection: {
    p: 4,
    width: '100%',
    height: '100%',
    overflow: 'auto',
  }
}

export default App;
