import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form'; // form handling
import { useNavigate } from 'react-router-dom';
import { z } from 'zod'; // schema validation
import { useAppDispatch } from '../../app/hooks';
import { signinGetUserDataAsync } from './authSlice';

const Schema = z.object({
  username: z.string().min(1, 'Please enter your username'),
  password: z.string().min(1, 'Please enter your password'),
});

type FormSchema = z.infer<typeof Schema>;

export function Signin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<FormSchema>({
    resolver: zodResolver(Schema),
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmitHandler = useCallback(
    async (data: FormSchema) => {
      const actionResult = await dispatch(
        signinGetUserDataAsync({
          username: data.username,
          password: data.password,
        })
      );

      if (signinGetUserDataAsync.rejected.match(actionResult)) {
        // The action was rejected
        const errorMessage = actionResult.payload;
        if (errorMessage) {
          setError('root', {
            type: 'custom',
            message: 'Invalid credentials (' + errorMessage + ')',
          });
        } else {
          setError('root', {
            type: 'custom',
            message: 'Invalid credentials',
          });
        }
      } else {
        // The action was fulfilled, clear form values
        reset();
        navigate('/dashboard');
      }
    },
    [dispatch, setError, reset, navigate]
  );

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Box display='flex' flexDirection='column' gap={2} width='100%'>
        <Box width='100%' display='flex' flexDirection='column' gap={1}>
          <TextField
            id='signin_username'
            label='Username'
            variant='outlined'
            error={Boolean(errors.username)}
            helperText={errors.username?.message}
            {...register('username')}
          />
          <TextField
            id='signin_password'
            label='Password'
            type='password'
            variant='outlined'
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            {...register('password')}
          />
        </Box>

        <Box>
          <Button
            type='submit'
            variant='text'
            color='inherit'
            sx={{ textTransform: 'none', fontSize: '1rem' }}
          >
            Continue
          </Button>
          {errors.root && (
            <Typography color='error'>{errors.root?.message}</Typography>
          )}
        </Box>
      </Box>
    </form>
  );
}
