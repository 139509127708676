import React from 'react'

export const NeverminedWidget = () => {
  const onWidgetElementMounted = (widgetElement: HTMLElement | null) => {
    if (widgetElement) {
      insertWidgetScriptBefore(
        'https://widgets.arbitrum.nevermined.app/nvm-one-widget-loader.js',
        widgetElement,
      )
    }
  }

  return (
    <div
      ref={onWidgetElementMounted}
      className="nvm-one-widget"
      {...{
        ['nvm-did']: 'did:nv:2dbfb9f940fec8baf39abf599b7d0a6a1f5bb117f5d3ae85d94a81fee04d2427',
        ['nvm-wid']: 'wid-258d7d28-c2e6-4c02-9de2-f38fa7b384d3',
        ['nvm-cta-text']: 'Buy Now',
        ['nvm-theme']: 'dark',
        ['nvm-layout']: 'horizontal',
        ['nvm-cta-text-color']: '#ffffff',
        ['nvm-cta-bg-color']: '#3e95ff',
      }}
    />
  )
}

const insertWidgetScriptBefore = (srcFile: string, insertBeforeElement: HTMLElement) => {
    const selector = `script[src='${srcFile}']`
  
    if (document.querySelectorAll(selector).length > 0) {
      return
    }
  
    const script = document.createElement('script')
    script.src = srcFile
    script.defer = true
  
    insertBeforeElement.parentNode?.insertBefore(script, insertBeforeElement)
  }