import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import LotName from '../components/LotName';
import NodeName from '../components/NodeName';
import { TimeInFuture } from '../components/TimeAgo';
import UserName from '../components/UserName';
import { selectUser } from '../features/auth/authSlice';
import { LotList } from '../features/lot/LotList';
import { ModuleList } from '../features/module/ModuleList';
import { NodeList } from '../features/node/NodeList';
import {
  fetchAllLotDataAsync,
  selectAuctions,
  selectBids,
  selectWins,
} from '../features/lot/lotSlice';
import { Auction } from '../schema/auction';
import { NeverminedWidget } from '../components/NeverminedWidget';

const Dashboard = () => {
  const { initialized } = useAppSelector((state) => state.lot);
  const user = useAppSelector(selectUser);
  const auctions = useAppSelector(selectAuctions);
  const wins = useAppSelector(selectWins);
  const bids = useAppSelector(selectBids);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!initialized && user) {
      dispatch(fetchAllLotDataAsync({ id: user?.id ?? '' }));
    }
  }, [user, dispatch, initialized]);

  return (
    <Box>
      <Box sx={styles.columnsContainer}>

        <Card sx={styles.dashboardCard}>
          <CardHeader title='Modules' sx={styles.dashboardCardHeader} />
          <CardContent>
            <ModuleList />
          </CardContent>
        </Card>

        <Card sx={styles.dashboardCard}>
          <CardHeader title='Nodes' sx={styles.dashboardCardHeader} />
          <CardContent>
            <NodeList />
          </CardContent>
        </Card>

        <Card sx={styles.dashboardCard}>
          <CardHeader title='Tasks' sx={styles.dashboardCardHeader} />
          <CardContent>
            <LotList />
          </CardContent>
        </Card>

      </Box>
    </Box>
  );
};

export default Dashboard;

/**
 * @type {import("@mui/material").SxProps}
 */

const styles = {
  pageTitle: {
    mb: 2,
  },
  columnsContainer: {
    columns: '280px 3',
    maxWidth: 1400,
  },
  dashboardCard: {
    margin: 1,
  },
  dashboardCardHeader: {
    padding: '16px 16px 0px 16px',
  },
  amount: {
    textAlign: 'right',
  },
  view: {
    textAlign: 'right',
  },
};
