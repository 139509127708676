import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { authenticateGetUserDataAsync, selectAuth } from './authSlice';

export default () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const authenticated = auth.authenticated;
  const status = auth.status;

  useEffect(() => {
    const initializeAuth = async () => {
      if (
        !authenticated &&
        status !== 'loading' &&
        typeof localStorage !== 'undefined'
      ) {
        const token = localStorage.getItem('token');
        if (token) {
          console.log('authenticating with token');
          await dispatch(authenticateGetUserDataAsync({ token }));
        }
      }
    };
    initializeAuth();
  }, [dispatch]);

  return null;
};
