import React from 'react';
import { Link, Typography } from '@mui/material';
export function Footer() {
  return (
    <Typography variant='body2' color='text.secondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://naptha.ai/'>
        Naptha AI
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
