import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useAppDispatch } from '../../app/hooks';
import {
  checkUsernameExistenceAsync,
  signupGetUserDataAsync,
} from './authSlice';

const SignupFormSchema = z.object({
  name: z.string().min(1, 'Please enter your name'),
  username: z.string().min(1, 'Please enter a username'),
  password: z.string().min(8, 'Password must be at least 8 characters'),
  inviteCode: z.string().min(1, 'Please enter an invite code'),
});

type FormSchema = z.infer<typeof SignupFormSchema>;

export function Signup() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<FormSchema>({
    // on blur
    mode: 'onBlur',
    resolver: zodResolver(SignupFormSchema),
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmitHandler = useCallback(
    async (data: FormSchema) => {
      // Check if the username exists, avoid async validation in Zod Schema
      const usernameExists = await dispatch(
        checkUsernameExistenceAsync(data.username)
      );
      if (usernameExists.payload) {
        setError('username', {
          type: 'manual',
          message: 'A user already exists with this username',
        });
        return;
      }

      const actionResult = await dispatch(
        signupGetUserDataAsync({
          name: data.name,
          username: data.username,
          password: data.password,
          inviteCode: data.inviteCode,
        })
      );

      if (signupGetUserDataAsync.rejected.match(actionResult)) {
        // The action was rejected
        const errorMessage = actionResult.payload;
        console.log('errorMessage', errorMessage);
        if (errorMessage === 'There was a problem with the database: No record was returned') {
          setError('inviteCode', {
            type: 'manual',
            message: 'Invalid invite code',
          });
        } else if (errorMessage) {
          setError('root', {
            type: 'custom',
            message: 'Signup failed (' + errorMessage + ')',
          });
        } else {
          setError('root', {
            type: 'custom',
            message: 'Signup failed',
          });
        }
      } else {
        // The action was fulfilled, clear form values
        reset();
        navigate('/dashboard');
      }
    },
    [dispatch, setError, reset, navigate]
  );

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Box display='flex' flexDirection='column' gap={2} width='100%'>
        <Box width='100%' display='flex' flexDirection='column' gap={1}>
          <TextField
            id='signup_name'
            label='Name'
            variant='outlined'
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            {...register('name')}
          />
          <TextField
            id='signup_username'
            label='Username'
            variant='outlined'
            error={Boolean(errors.username)}
            helperText={errors.username?.message}
            {...register('username')}
          />
          <TextField
            id='signup_password'
            label='Password'
            type='password'
            variant='outlined'
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            {...register('password')}
          />
          <TextField
            id='signup_inviteCode'
            label='Invite Code'
            variant='outlined'
            error={Boolean(errors.inviteCode)}
            helperText={errors.inviteCode?.message}
            {...register('inviteCode')}
          />
        </Box>

        <Box>
          <Button
            type='submit'
            variant='text'
            color='inherit'
            sx={{ textTransform: 'none', fontSize: '1rem' }}
          >
            Continue
          </Button>
          {errors.root && (
            <Typography color='error'>{errors.root?.message}</Typography>
          )}
        </Box>
      </Box>
    </form>
  );
}
