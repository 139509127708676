import { indigo } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// typography: {
//   fontFamily: [
//     'ui-monospace',
//     'SFMono-Regular',
//     'Menlo',
//     'Monaco',
//     'Consolas',
//     'Liberation Mono',
//     'Courier New',
//     'monospace',
//   ].join(','),
// },

const napthaTheme = (darkMode: boolean) =>
  createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#FFFFFF',
        dark: indigo['A700'],
        light: indigo['A200'],
      },
      secondary: {
        main: '#000000',
        
      },
    },
    components: {

    },
  });

export default napthaTheme;
