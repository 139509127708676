import { Chip, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import UserName from '../../components/UserName';
import { Node, NodeOrganization } from '../../schema/node';

interface NodeComponentProps {
  organization?: NodeOrganization;
  node?: Node;
}

const NodeComponent: React.FC<NodeComponentProps> = ({
  organization,
  node,
}) => {
  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} md={4}>
          <Typography variant='h5' color='secondary'>
            <img
              src={"/logo-flame.png"}
              alt={organization ? organization.name : ''}
              style={{
                width: '50px',
                height: '50px',
                objectFit: 'contain',
                borderRadius: '10%',
                marginRight: '10px',
              }}
            />
            {node?.id}
          </Typography>
        </Grid>
      </Grid>

      <Typography variant='subtitle2'>
        <strong>Node Description:</strong>
      </Typography>
      <Typography variant='body1' paragraph>
        {node?.description}
      </Typography>

      <Typography variant='subtitle1'>
        <strong>Owner:</strong> <UserName user_id={node?.user_id ?? ''} />
      </Typography>
      <Typography variant='subtitle1'>
        <strong>Num GPUs:</strong> {node?.num_gpus}
      </Typography>
      <Typography variant='subtitle1'>
        <strong>Address:</strong> {node?.address}
      </Typography>
      <Typography variant='subtitle1'>
        <strong>OS:</strong> {node?.os}
      </Typography>
    </Paper>
  );
};

export default NodeComponent;
