import { Chip, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import UserName from '../../components/UserName';
import { Category } from '../../schema/category';
import { License } from '../../schema/license';
import { Module, ModuleOrganization } from '../../schema/module';
import { Tag } from '../../schema/tag';

interface ModuleComponentProps {
  organization?: ModuleOrganization;
  module?: Module;
  category: Category;
  tags: Tag[];
  license: License;
}

const ModuleComponent: React.FC<ModuleComponentProps> = ({
  organization,
  module,
  category,
  tags,
  license,
}) => {
  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} md={4}>
          <Typography variant='h5' color='secondary'>
            <img
              src={"/logo-flame.png"}
              alt={organization ? organization.name : ''}
              style={{
                width: '50px',
                height: '50px',
                objectFit: 'contain',
                borderRadius: '10%',
                marginRight: '10px',
              }}
            />
            {module?.author.split(":")[1]}/{module?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Chip label={category?.name} color='secondary' />
        </Grid>
        <Grid item xs={12} md={4}>
          {tags.map((tag, index) => (
            <Chip key={index} label={tag.name} />
          ))}
        </Grid>
      </Grid>

      <Typography variant='subtitle2'>
        <strong>Module Description:</strong>
      </Typography>
      <Typography variant='body1' paragraph>
        {module?.description}
      </Typography>

      <Typography variant='subtitle1'>
        <strong>Author:</strong> {module?.author.split(":")[1]} 
      </Typography>

      <Typography variant='subtitle1'>
        <strong>License:</strong> {license?.license_type}
      </Typography>
    </Paper>
  );
};

export default ModuleComponent;
