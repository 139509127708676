import { Box, Button, TextField } from '@mui/material';
import protobuf from 'protobufjs';
import React, { useCallback, useMemo } from 'react';
import { FieldError, FieldErrorsImpl, Merge, useForm } from 'react-hook-form';
import { useAppDispatch } from '../app/hooks';
import { consumeAsync } from '../features/lot/lotSlice';
import { Lot } from '../schema/lot';

interface DynamicFormProps {
  lot: Lot
  win_id: string;
  protobufString: string;
}

interface Field {
  name: string;
  type: 'sint64' | 'float' | 'bool' | 'bytes' | 'string';
  optional: boolean;
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  lot,
  win_id,
  protobufString,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const dispatch = useAppDispatch();
  const root = useMemo(
    () => protobuf.parse(protobufString).root,
    [protobufString]
  ); // void unnecessary parsing on each render
  const Args = root.lookupType('Request');

  function isFieldType(type: string): type is Field['type'] {
    return ['sint64', 'float', 'bool', 'bytes', 'string'].includes(type);
  }

  const parsedFields: Field[] = useMemo(
    () =>
      Args.fieldsArray.map((field) => {
        if (!isFieldType(field.type)) {
          throw new Error(`Invalid field type: ${field.type}`);
        }
        return {
          name: field.name,
          type: field.type as 'sint64' | 'float' | 'bool' | 'bytes' | 'string',
          optional: field.options?.proto3_optional || false,
        };
      }),
    [root]
  );

  const onSubmit = useCallback(
    async (data: any) => {
      const fieldTypeMap = parsedFields.reduce(
        (acc, field) => {
          acc[field.name] = field.type;
          return acc;
        },
        {} as Record<string, string>
      );

      const sanitizedData = Object.entries(data).reduce((acc, [key, value]) => {
        const fieldType = fieldTypeMap[key];

        if (value === '') {
          acc[key] = null;
        } else {
          switch (fieldType) {
            case 'sint64':
            case 'float':
              acc[key] = Number(value);
              break;
            case 'bool':
              acc[key] = value === 'true';
              break;
            case 'bytes': {
              let valueStr = '';
              if (typeof value === 'string') {
                valueStr = value.toString();
              }
              // Estimate the length of the decoded bytes (roughly 3/4 of the base64 string length)
              const estimatedLength = Math.ceil((valueStr.length * 3) / 4);
              const buffer = new Uint8Array(estimatedLength);
              const actualLength = protobuf.util.base64.decode(
                valueStr,
                buffer,
                0
              );
              // Slice the buffer in case the actual decoded length is less than the estimate
              acc[key] = buffer.slice(0, actualLength);
              break;
            }
            default:
              // strings
              acc[key] = value;
          }
        }
        return acc;
      }, {} as any);
      const argsMessage = Args.create(sanitizedData);
      const buffer = Args.encode(argsMessage).finish();
      const base64String = protobuf.util.base64.encode(
        buffer,
        0,
        buffer.length
      );
      const consumeResult = await dispatch(
        consumeAsync({
          lot_id: lot.id,
          job_type: 'template',
          template_name: lot.name,
          win_id: win_id,
          encodedRequest: base64String,
        })
      );
      // FIXME: handle consumeResult
      reset();
    },
    [dispatch, parsedFields, win_id, reset]
  );

  function getErrorMessage(
    field: Field,
    error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined
  ) {
    if (error === undefined) {
      return 'unknown error';
    }
    if (field.type === 'sint64' || field.type === 'float') {
      return error.type === 'validate'
        ? 'The value must be a number'
        : 'This field is required';
    }
    return 'This field is required';
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {parsedFields.map((field) => (
        <Box key={field.name}>
          <TextField
            sx={{ width: '100%' }}
            id={field.name}
            multiline={field.type !== 'sint64' && field.type !== 'float'}
            label={field.name}
            variant='outlined'
            inputProps={{
              style: {
                resize:
                  field.type === 'sint64' || field.type === 'float'
                    ? 'none'
                    : 'vertical',
                maxWidth: '100%',
              },
            }}
            error={Boolean(errors[field.name])} // Set error prop based on whether there's an error for this field
            helperText={
              errors[field.name]
                ? getErrorMessage(field, errors[field.name])
                : ''
            } // Display the appropriate error message
            {...register(field.name, {
              required: !field.optional,
              validate: (val) => {
                if (
                  (field.type === 'sint64' || field.type === 'float') &&
                  (val !== undefined || val !== null || val !== '')
                ) {
                  return !isNaN(val);
                }
              },
            })}
            type='text'
          />
        </Box>
      ))}
      <Button
        key='bid-button'
        variant='contained'
        color='primary'
        type='submit'
      >
        Run Job
      </Button>
    </form>
  );
};

export default DynamicForm;
