import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import Database from '../api/surreal';
import { authSlice } from '../features/auth/authSlice';
import { lotSlice } from '../features/lot/lotSlice';
import { moduleSlice } from '../features/module/moduleSlice';
import { nodeSlice } from '../features/node/nodeSlice';
import { walletSlice } from '../features/wallet/walletSlice';

const db = new Database();
db.init();

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [walletSlice.name]: walletSlice.reducer,
    [lotSlice.name]: lotSlice.reducer,
    [moduleSlice.name]: moduleSlice.reducer,
    [nodeSlice.name]: nodeSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { db },
      },
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
