import z from 'zod';
import { record } from './zodrecord';

export const Module = z.object({
  id: record('module'),
  name: z.string(),
  description: z.string(),
  author: record('user'),
  request: z.string(),
  reply: z.string(),
  created: z.coerce.date().transform((date) => date.toISOString()),
  updated: z.coerce.date().transform((date) => date.toISOString()),
});
export type Module = z.infer<typeof Module>;

export const ModuleOrganization = z.object({
  id: record('organization'),
  name: z.string(),
  image: z.string(),
  owner: record('user'),
});
export type ModuleOrganization = z.infer<typeof ModuleOrganization>;

// FIXME: move organization back as a property of module
export const ModuleIsAssociatedWith = z.object({
  id: record('is_associated_with'),
  in: record('module'),
  out: record('organization'),
});
export type ModuleIsAssociatedWith = z.infer<typeof ModuleIsAssociatedWith>;

export const UserIsAssociatedWith = z.object({
  id: record('is_associated_with'),
  in: record('user'),
  out: record('organization'),
});
export type UserIsAssociatedWith = z.infer<typeof UserIsAssociatedWith>;
