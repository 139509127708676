import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Grid, IconButton, Paper, Snackbar, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TimeInFuture } from '../../components/TimeAgo';
import UserName from '../../components/UserName';
import CustomSnackbar, { SnackbarConfig } from '../../components/CustomSnackbar';
import { selectUser } from '../auth/authSlice';
import ModuleComponent from './ModuleComponent';
import {
  fetchAllModuleDataAsync,
  selectCategoryByModuleId,
  selectLicenseByModuleId,
  selectModuleById,
  selectOrganizationByModuleId,
  selectTagsByModuleId,
} from './moduleSlice';
import {
  selectNodes,
  selectAuctionsByNodeId,
} from '../node/nodeSlice';
import { ThumbDownSharp, ThumbUpSharp, Close } from '@mui/icons-material';
import { Auction } from '../../schema/auction';

const Schema = z.object({
  auction_id: z.string().min(1, 'Please select an auction'),
  bid_amount: z
    .string()
    .refine((value) => !isNaN(parseFloat(value)), {
      message: 'Invalid input: Please enter a number',
    })
    .refine((value) => parseFloat(value) >= 0.01, {
      message: 'Please enter a bid amount greater than .01',
    }),
});

type FormSchema = z.infer<typeof Schema>;

export function Module() {
  const [selectedAuction, setSelectedAuction] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormSchema>({
    resolver: zodResolver(Schema),
  });
  const { moduleid } = useParams();
  const module = useAppSelector(selectModuleById(moduleid ?? ''));
  const nodes = useAppSelector(selectNodes);
  const { initialized } = useAppSelector((state) => state.module);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarConfig>({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (!initialized && user) {
      dispatch(fetchAllModuleDataAsync({ id: user?.id ?? '' }));
    }
  }, [user, dispatch, initialized]);

  const organization = useAppSelector(selectOrganizationByModuleId(module?.id ?? ''));
  // FIXME: https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
  const tags = useAppSelector(selectTagsByModuleId(module?.id ?? ''));
  const category = useAppSelector(selectCategoryByModuleId(module?.id ?? ''));
  const license = useAppSelector(selectLicenseByModuleId(module?.id ?? ''));

  const handleClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const handleAction = useCallback((message: any, auctionId: string) => {
    setSnackbarConfig({
      open: true,
      message: message,
      actionComponent: auctionId ? (
        <React.Fragment>
          <RouterLink to={`/auction/${auctionId}`} style={{ textDecoration: 'none' }}>
            <Button color="secondary" size="small">
              VIEW AUCTION
            </Button>
          </RouterLink>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <Close />
          </IconButton>
        </React.Fragment>
      ) : null,
    });
  }, []);

  return (
    <>
    <Box>
      <ModuleComponent
        module={module}
        organization={organization}
        category={category}
        tags={tags}
        license={license}
      />
      {/* <Paper elevation={3} sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}>
        <Typography variant='h5' gutterBottom color='primary'>
          Available Nodes
        </Typography>
        <Grid container spacing={3} sx={styles.sellerGrid}>
          {nodes.map((node) => {
              const auctions = useAppSelector(selectAuctionsByNodeId(node?.id ?? ''));
              return (
                <React.Fragment key={node.id}>
                  <Grid item xs={12} md={3}>
                    <Typography variant='body2'>
                      {node.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} sx={styles.view}>
                    <Button
                      component={RouterLink}
                      to={`/node/${node.id}`}
                    >
                      View Node
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={3} sx={styles.view}>
                    <Button
                      component={RouterLink}
                      to={`/auction/${auctions[0].id}`}
                    >
                      Run on Node
                    </Button>
                  </Grid>
                </React.Fragment>
              );
            })}
          {nodes.length === 0 && (
            <Grid item xs={12} sm={12}>
              {nodes[0].id}No nodes available to run this module right now.
            </Grid>
          )}
        </Grid>
      </Paper> */}
    </Box>
    <CustomSnackbar
        open={snackbarConfig.open}
        message={snackbarConfig.message}
        handleClose={handleClose}
        actionComponent={snackbarConfig.actionComponent}
      />
    </>
  );
}

/**
 * @type {import("@mui/material").SxProps}
 */

const styles = {
  sellerGrid: {
    mt: 2,
  },
  amount: {
    textAlign: 'right',
  },
  view: {
    textAlign: 'right',
  },
};
