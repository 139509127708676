import z from 'zod';
import { record } from './zodrecord';

export const Win = z.object({
  id: record('wins'),
  amount: z.number(),
  in: record('user'),
  out: record('auction'),
});
export type Win = z.infer<typeof Win>;

export const Auction = z.object({
  id: record('auction'),
  listing: record('lot'),
  node: record('node'),
  published: z.boolean(),
  hammer_time: z.coerce.date().transform((date) => date.toISOString()),
  consigner: record('user'),
  buy_it_now_price: z.number().optional(),
});
export type Auction = z.infer<typeof Auction>;

export const Bid = z.object({
  id: record('bids'),
  amount: z.number(),
  in: record('user'),
  out: record('auction'),
});
export type Bid = z.infer<typeof Bid>;

export const Rate = z.object({
  id: record('rates'),
  rating: z.boolean(),
  auction: record('auction'),
  in: record('user'),
  out: record('user'),
});
export type Rate = z.infer<typeof Rate>;

export const Rating = z.object({
  id: record('rating'),
  lot: record('lot'),  
  rating: z.boolean(),
  user: record('user'),
});
export type Rating = z.infer<typeof Rating>;

export const JobType = z.union([z.literal('template'), z.literal('docker')]);

export const TemplateParams = z.object({
  template_name: z.string(),
  template_args: z.record(z.string()).optional(),
});

export const Encoded = z.object({
  encoded: z.string(),
});

export const Consume = z.object({
  id: record('consume'),
  auction_ref: record('wins'),
  consumer: record('user'),
  request: Encoded,
  reply: Encoded.optional(),
  job_type: JobType,
  status: z.string().optional(),
  lot: record('lot'),
  template_params: TemplateParams
});
export type Consume = z.infer<typeof Consume>;
