// CustomSnackbar.js
import React from 'react';
import { Snackbar, Button } from '@mui/material';

type SnackbarProps = {
    open: boolean;
    message: string;
    handleClose: () => void;
    actionComponent: React.ReactNode | null;
  };

export interface SnackbarConfig {
  open: boolean;
  message: string;
  actionComponent?: React.ReactNode;
}

const CustomSnackbar = ({ open, message, handleClose, actionComponent }: SnackbarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      action={actionComponent}
    />
  );
};

export default CustomSnackbar;
