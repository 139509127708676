import { createSlice } from '@reduxjs/toolkit';
import { User, UserDisplay } from '../../schema/user';
import { createAppAsyncThunk, handleThunkError } from '../createAppAsyncThunk';
import { resetState as lotResetState } from '../lot/lotSlice';

export interface AuthState {
  authenticated: boolean;
  user: User | null;
  token: string | null;
  status: 'idle' | 'loading' | 'failed';
  users: UserDisplay[];
}

export const signinGetUserDataAsync = createAppAsyncThunk(
  'auth/signinGetUserData',
  async (userData: { username: string; password: string }, thunkAPI) => {
    const signinAsyncResult = await thunkAPI.dispatch(signinAsync(userData));

    if (signinAsync.rejected.match(signinAsyncResult)) {
      return thunkAPI.rejectWithValue(String(signinAsyncResult.payload));
    } else {
      // Handle success
      await thunkAPI.dispatch(getUserDataAsync());
    }
  }
);

export const signinAsync = createAppAsyncThunk(
  'auth/signin',
  async (userData: { username: string; password: string }, thunkAPI) => {
    try {
      const token = await thunkAPI.extra.db.signinDB(
        userData.username,
        userData.password
      );
      if (!token) {
        return thunkAPI.rejectWithValue('Invalid username or password');
      }
      // store user's token in local storage
      localStorage.setItem('token', token);
      return token;
    } catch (error) {
      return handleThunkError(error, thunkAPI);
    }
  }
);

export const authenticateAsync = createAppAsyncThunk(
  'auth/authenticate',
  async (userData: { token: string }, thunkAPI) => {
    try {
      const token = await thunkAPI.extra.db.authenticateDB(userData.token);
      if (!token) {
        return thunkAPI.rejectWithValue('Invalid token');
      }
      return token;
    } catch (error) {
      return handleThunkError(error, thunkAPI);
    }
  }
);

export const authenticateGetUserDataAsync = createAppAsyncThunk(
  'auth/authenticateGetUserDataAsync',
  async (userData: { token: string }, thunkAPI) => {
    const authenticateAsyncResult = await thunkAPI.dispatch(
      authenticateAsync(userData)
    );
    if (authenticateAsync.rejected.match(authenticateAsyncResult)) {
      return thunkAPI.rejectWithValue(String(authenticateAsyncResult.payload));
    } else {
      // Handle success
      await thunkAPI.dispatch(getUserDataAsync());
    }
  }
);

export const signupGetUserDataAsync = createAppAsyncThunk(
  'auth/signupGetUserData',
  async (
    userData: { name: string; username: string; password: string; inviteCode: string},
    thunkAPI
  ) => {
    const signupAsyncResult = await thunkAPI.dispatch(signupAsync(userData));

    if (signupAsync.rejected.match(signupAsyncResult)) {
      return thunkAPI.rejectWithValue(String(signupAsyncResult.payload));
    } else {
      // Handle success
      await thunkAPI.dispatch(getUserDataAsync());
    }
  }
);

export const getUserDataAsync = createAppAsyncThunk(
  'auth/getUserData',
  async (_, thunkAPI) => {
    try {
      const user = await thunkAPI.extra.db.getSignedInUser();
      if (!user) {
        return thunkAPI.rejectWithValue('No user found');
      }
      await thunkAPI.dispatch(fetchUsersDisplayAsync());
      return user;
    } catch (error) {
      return handleThunkError(error, thunkAPI);
    }
  }
);

export const signupAsync = createAppAsyncThunk(
  'auth/signup',
  async (
    userData: { name: string; username: string; password: string; inviteCode: string},
    thunkAPI
  ) => {
    try {
      const token = await thunkAPI.extra.db.signupDB(
        userData.name,
        userData.username,
        userData.password,
        userData.inviteCode,
      );
      if (!token) {
        return thunkAPI.rejectWithValue('Signup failed');
      }
      // store user's token in local storage
      localStorage.setItem('token', token);
      return token;
    } catch (error) {
      return handleThunkError(error, thunkAPI);
    }
  }
);

export const signoutAsync = createAppAsyncThunk(
  'auth/signout',
  async (_, thunkAPI) => {
    try {
      localStorage.removeItem('token');
      const invalidated = thunkAPI.extra.db.invalidateUser();
      thunkAPI.dispatch(resetState());
      thunkAPI.dispatch(lotResetState());
      return invalidated;
    } catch (error) {
      return handleThunkError(error, thunkAPI);
    }
  }
);

export const checkUsernameExistenceAsync = createAppAsyncThunk(
  'auth/checkUsername',
  async (username: string, thunkAPI) => {
    const db = thunkAPI.extra.db;
    const usernameExists = await db.doesUsernameExist(username);
    return usernameExists;
  }
);

export const checkInviteCodeValidityAsync = createAppAsyncThunk(
  'auth/checkInviteCode',
  async (inviteCode: string, thunkAPI) => {
    const db = thunkAPI.extra.db;
    const inviteCodeIsValid = await db.isInviteCodeValid(inviteCode);
    return inviteCodeIsValid;
  }
);

export const fetchUsersDisplayAsync = createAppAsyncThunk(
  'auth/fetchUsersDisplay',
  async (_, thunkAPI) => {
    const usersDisplay = await thunkAPI.extra.db.getUsersDisplay();
    return usersDisplay;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authenticated: false,
    user: null,
    token: null,
    status: 'idle',
  } as AuthState,
  reducers: {
    resetState: (state) => {
      state.authenticated = false;
      state.user = null;
      state.token = null;
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signinGetUserDataAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(signinAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signinAsync.fulfilled, (state, action) => {
        state.token = action.payload;
        state.status = 'idle';
        state.authenticated = true;
      })
      .addCase(authenticateAsync.fulfilled, (state, action) => {
        state.token = action.payload;
        state.authenticated = true;
      })
      .addCase(signinAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getUserDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserDataAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload;
      })
      .addCase(getUserDataAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(signupAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signupAsync.fulfilled, (state, action) => {
        state.token = action.payload;
        state.status = 'idle';
        state.authenticated = true;
      })
      .addCase(signupAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(signoutAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signoutAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.authenticated = false;
        state.user = null;
        state.token = null;
      })
      .addCase(fetchUsersDisplayAsync.fulfilled, (state, action) => {
        state.users = action.payload;
      });
  },
});

export const { resetState } = authSlice.actions;

export type AuthSlice = {
  [authSlice.name]: ReturnType<(typeof authSlice)['reducer']>;
};

export const selectUser = (state: { auth: AuthState }) => state.auth.user;
export const selectAuth = (state: { auth: AuthState }) => state.auth;
export const selectUsers = (state: { auth: AuthState }) => state.auth.users;
export const selectUserById = 
  (id: string) => (state: { auth: AuthState }) =>
    state.auth.users?.find((user) => user.id === id);
