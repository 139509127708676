import React from 'react';

function Time({ isoDate: isoDate }: { isoDate: string }) {
  const date = new Date(isoDate);
  if (date > new Date()) {
    return TimeInFuture({ isoDate: isoDate })
  } else {
    return TimeAgo({ isoDate: isoDate })
  }
}

function TimeAgo({ isoDate: isoDate }: { isoDate: string }) {
  const date = new Date(isoDate);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  let timeAgo = '';

  if (diffInDays > 0) {
    timeAgo = `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
  } else if (diffInHours > 0) {
    timeAgo = `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  } else if (diffInMinutes > 0) {
    timeAgo = `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  } else {
    timeAgo = 'Just now';
  }

  return (
    <time dateTime={isoDate} title={date.toUTCString()}>
      {timeAgo}
    </time>
  );
}

function TimeInFuture({ isoDate: isoDate }: { isoDate: string }) {
  const date = new Date(isoDate);
  const now = new Date();
  const diffInSeconds = Math.floor((date.getTime() - now.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  
  let TimeInFuture = '';
  if (diffInDays > 0) {
    TimeInFuture = `ends in ${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
  } else if (diffInHours > 0) {
    TimeInFuture = `ends in ${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
  }
  else if (diffInMinutes > 0) {
    TimeInFuture = `ends in ${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''}`;
  } else {
    TimeInFuture = 'Just now';
  }
  return (
    <time dateTime={isoDate} title={date.toUTCString()}>
      {TimeInFuture}
    </time>
  );
}
export { Time, TimeInFuture, TimeAgo };