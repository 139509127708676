import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Link,
    Typography,
  } from '@mui/material';
  import React, { useEffect } from 'react';
  import { Link as RouterLink } from 'react-router-dom';
  import { useAppDispatch, useAppSelector } from '../../app/hooks';
  import { TimeAgo } from '../../components/TimeAgo';
  import { selectUser } from '../auth/authSlice';
  import {
    fetchAllModuleDataAsync,
    selectModuleAssociations,
    selectModules,
    selectOrganizations,
  } from './moduleSlice';
  
  export function Modules() {
    const modules = useAppSelector(selectModules);
    const organizations = useAppSelector(selectOrganizations);
    const allModuleAssociations = useAppSelector(selectModuleAssociations);
    const { initialized } = useAppSelector((state) => state.module);
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();
  
    useEffect(() => {
      if (!initialized && user) {
        dispatch(fetchAllModuleDataAsync({ id: user?.id ?? '' }));
      }
    }, [user, dispatch, initialized]);
  
    return (
      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ sm: 4, md: 12 }}>
        {modules.map((module) => {
          const moduleAssociation = allModuleAssociations.find(
            (moduleAssociation) => moduleAssociation.in === module.id
          );
          const organizationDetails = organizations.find(
            (organization) => moduleAssociation?.out === organization.id
          );
          return (
            <Grid item sm={4} md={6} key={module.id}>
              <Card>
                <CardActionArea className='card-hover'>
                  <Link component={RouterLink} to={`/module/${module.id}`}>
                    <CardContent>
                      <Box display='flex' alignItems='center' gap={2}>
                        <img
                          src={
                            "/logo-flame.png"
                          }
                          alt={
                            organizationDetails ? organizationDetails.name : ''
                          }
                          style={{
                            width: '30px',
                            height: '30px',
                            objectFit: 'contain',
                            borderRadius: '10%',
                          }}
                        />
                        <Typography variant='h5' component='div' color='text.secondary'>
                          {module.author.split(":")[1]}/{module.name}
                        </Typography>
                      </Box>
                      <Typography
                        variant='body2'
                        color='text.secondary'
                        marginTop={2}
                      >
                        <TimeAgo isoDate={module.updated} /> • 454 likes
                      </Typography>
                    </CardContent>
                  </Link>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  }
  