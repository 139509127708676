import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import LotName from '../components/LotName';
import NodeName from '../components/NodeName';
import { TimeInFuture } from '../components/TimeAgo';
import UserName from '../components/UserName';
import { selectUser } from '../features/auth/authSlice';
import {
  fetchAllLotDataAsync,
  selectAuctions,
  selectBids,
  selectWins,
} from '../features/lot/lotSlice';
import { Auction } from '../schema/auction';
import { NeverminedWidget } from '../components/NeverminedWidget';

const Dashboard = () => {
  const { initialized } = useAppSelector((state) => state.lot);
  const user = useAppSelector(selectUser);
  const auctions = useAppSelector(selectAuctions);
  const wins = useAppSelector(selectWins);
  const bids = useAppSelector(selectBids);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!initialized && user) {
      dispatch(fetchAllLotDataAsync({ id: user?.id ?? '' }));
    }
  }, [user, dispatch, initialized]);

  return (
    <Box>
      <Typography sx={styles.pageTitle} variant='h5'>
        Buy Credits
      </Typography>
      <NeverminedWidget />
      <Box sx={styles.columnsContainer}>
        <Card sx={styles.dashboardCard}>
          <CardHeader title='My Purchases' sx={styles.dashboardCardHeader} />
          <CardContent>
            <Grid container spacing={1} alignItems={'center'}>
              {wins.map((win) =>
                auctions.map((auction: Auction) => {
                  return (
                    win.out == auction.id && (
                      <React.Fragment key={win.id}>
                        <Grid item xs={12} md={6}>
                          <Typography variant='body2'>
                            {auction.node}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Typography variant='body2'>
                            {new Date(
                              auction?.hammer_time ?? Date()
                            ).toLocaleDateString()}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={2} sx={styles.amount}>
                          <Typography variant='body1'>{`$${win.amount}`}</Typography>
                        </Grid>
                        <Grid item xs={12} md={2} sx={styles.view}>
                          <Button
                            component={RouterLink}
                            to={`/auction/${auction.id}`}
                          >
                            View
                          </Button>
                        </Grid>
                      </React.Fragment>
                    )
                  );
                })
              )}
            </Grid>
          </CardContent>
        </Card>

        <Card sx={styles.dashboardCard}>
          <CardContent>
            <Typography variant='h5'>Wallet and Transactions</Typography>

          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Dashboard;

/**
 * @type {import("@mui/material").SxProps}
 */

const styles = {
  pageTitle: {
    mb: 2,
  },
  columnsContainer: {
    columns: '280px 3',
    maxWidth: 1400,
  },
  dashboardCard: {
    margin: 1,
  },
  dashboardCardHeader: {
    padding: '16px 16px 0px 16px',
  },
  amount: {
    textAlign: 'right',
  },
  view: {
    textAlign: 'right',
  },
};
