import { createSlice } from '@reduxjs/toolkit';
import { Category, InCategory } from '../../schema/category';
import { License, HasLicense } from '../../schema/license';
import {
  ModuleOrganization,
  Module,
  ModuleIsAssociatedWith,
} from '../../schema/module';
import { Tag, IsTagged } from '../../schema/tag';
import { createAppAsyncThunk } from '../createAppAsyncThunk';
import { Consume, Rate, Rating } from '../../schema/auction';

export interface ModuleState {
  initialized: boolean;
  consumes: Consume[];
  rates: Rate[];
  ratings: Rating[];
  modules: Module[];
  organizations: ModuleOrganization[];
  moduleAssociations: ModuleIsAssociatedWith[];
  categories: Category[];
  topLevelCategories: Category[];
  licenses: License[];
  tags: Tag[];
  isTagged: IsTagged[];
  inCategory: InCategory[];
  hasLicense: HasLicense[];
}

const initialState: ModuleState = {
  initialized: false,
  rates: [],
  ratings: [],
  consumes: [],
  modules: [],
  organizations: [],
  moduleAssociations: [],
  categories: [],
  topLevelCategories: [],
  licenses: [],
  tags: [],
  isTagged: [],
  inCategory: [],
  hasLicense: [],
};



export const consumeAsync = createAppAsyncThunk(
  'module/consume',
  async (consumeData: { module_id: string, job_type: string, template_name: string, win_id: string; encodedRequest: string }, thunkAPI) => {
    const consume = await thunkAPI.extra.db.consume(consumeData.module_id,
      consumeData.job_type, consumeData.template_name, consumeData.win_id, consumeData.encodedRequest);
    return consume;
  }
);

// load all data in parallel
export const fetchAllModuleDataAsync = createAppAsyncThunk(
  'module/loadData',
  async (userData: { id: string }, thunkAPI) => {
    await Promise.all([
      thunkAPI.dispatch(fetchRatesAsync()),
      thunkAPI.dispatch(fetchRatingsAsync()),
      thunkAPI.dispatch(fetchConsumesAsync()),
      thunkAPI.dispatch(fetchModulesAsync()),
      thunkAPI.dispatch(fetchCategoriesAsync()),
      thunkAPI.dispatch(fetchModuleAssociationsAsync()),
      thunkAPI.dispatch(fetchTopLevelCategoriesAsync()),
      thunkAPI.dispatch(fetchOrganizationsAsync()),
      thunkAPI.dispatch(fetchLicensesAsync()),
      thunkAPI.dispatch(fetchTagsAsync()),
      thunkAPI.dispatch(fetchIsTaggedAsync()),
      thunkAPI.dispatch(fetchInCategoryAsync()),
      thunkAPI.dispatch(fetchHasLicenseAsync()),
    ]);
  }
);

export const fetchRatesAsync = createAppAsyncThunk(
  'module/fetchRates',
  async (_, thunkAPI) => {
    const rates = await thunkAPI.extra.db.getRates();
    return rates;
  }
);

export const fetchRatingsAsync = createAppAsyncThunk(
  'module/fetchRatings',
  async (_, thunkAPI) => {
    const ratings = await thunkAPI.extra.db.getRatings();
    return ratings;
  }
);

export const fetchConsumesAsync = createAppAsyncThunk(
  'module/fetchConsumes',
  async (_, thunkAPI) => {
    const consumes = await thunkAPI.extra.db.getConsumes();
    return consumes;
  }
);

export const fetchModulesAsync = createAppAsyncThunk(
  'module/fetchModules',
  async (_, thunkAPI) => {
    const modules = await thunkAPI.extra.db.getModules();
    return modules;
  }
);

export const fetchOrganizationsAsync = createAppAsyncThunk(
  'module/fetchOrganizations',
  async (_, thunkAPI) => {
    const organizations = await thunkAPI.extra.db.getOrganizations();
    return organizations;
  }
);

export const fetchModuleAssociationsAsync = createAppAsyncThunk(
  'module/fetchModuleAssociations',
  async (_, thunkAPI) => {
    const moduleAssociations = await thunkAPI.extra.db.getModuleOrganizations();
    return moduleAssociations;
  }
);

export const fetchCategoriesAsync = createAppAsyncThunk(
  'module/fetchCategories',
  async (_, thunkAPI) => {
    const categories = await thunkAPI.extra.db.getCategories();
    return categories;
  }
);

export const fetchTopLevelCategoriesAsync = createAppAsyncThunk(
  'module/fetchTopLevelCategories',
  async (_, thunkAPI) => {
    const topLevelCategories = await thunkAPI.extra.db.getTopLevelCategories();
    return topLevelCategories;
  }
);

export const fetchLicensesAsync = createAppAsyncThunk(
  'module/fetchLicenses',
  async (_, thunkAPI) => {
    const licenses = await thunkAPI.extra.db.getLicenses();
    return licenses;
  }
);

export const fetchTagsAsync = createAppAsyncThunk(
  'module/fetchTags',
  async (_, thunkAPI) => {
    const tags = await thunkAPI.extra.db.getTags();
    return tags;
  }
);

export const fetchIsTaggedAsync = createAppAsyncThunk(
  'module/fetchIsTagged',
  async (_, thunkAPI) => {
    const tagged = await thunkAPI.extra.db.getIsTagged();
    return tagged;
  }
);

export const fetchInCategoryAsync = createAppAsyncThunk(
  'module/fetchInCategory',
  async (_, thunkAPI) => {
    const subcategorized = await thunkAPI.extra.db.getInCategory();
    return subcategorized;
  }
);

export const fetchHasLicenseAsync = createAppAsyncThunk(
  'module/fetchHasLicense',
  async (_, thunkAPI) => {
    const licensed = await thunkAPI.extra.db.getHasLicense();
    return licensed;
  }
);

export const killMessageStream = createAppAsyncThunk(
  'auth/killMessageStream',
  async (query_id: string, thunkAPI) => {
    const db = thunkAPI.extra.db;
    await db.kill(query_id);
    console.log('killMessageStream', query_id);
  }
);

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    resetState: (state) => {
      state.initialized = false;
      state.rates = [];
      state.modules = [];
      state.organizations = [];
      state.moduleAssociations = [];
      state.categories = [];
      state.topLevelCategories = [];
      state.licenses = [];
      state.tags = [];
      state.isTagged = [];
      state.inCategory = [];
      state.hasLicense = [];
    },
    updateConsumeData: (state, action) => {
      const consume = action.payload;
      const index = state.consumes.findIndex((c) => c.id === consume.id);
      if (index === -1) {
        state.consumes.push(consume);
      } else {
        state.consumes[index] = consume;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllModuleDataAsync.fulfilled, (state) => {
        state.initialized = true;
      })
      .addCase(consumeAsync.fulfilled, (state, action) => {
        const consume = action.payload;
        if (consume !== undefined && consume.id) {
          const index = state.consumes.findIndex((c) => c.id === consume.id);
          if (index === -1) {
            state.consumes.push(consume);
          } else {
            state.consumes[index] = consume;
          }
        }
      })
      .addCase(fetchRatesAsync.fulfilled, (state, action) => {
        state.rates = action.payload;
      })
      .addCase(fetchRatingsAsync.fulfilled, (state, action) => {
        state.ratings = action.payload;
      })
      .addCase(fetchConsumesAsync.fulfilled, (state, action) => {
        state.consumes = action.payload;
      })
      .addCase(fetchModulesAsync.fulfilled, (state, action) => {
        state.modules = action.payload;
      })
      .addCase(fetchModuleAssociationsAsync.fulfilled, (state, action) => {
        state.moduleAssociations = action.payload;
      })
      .addCase(fetchOrganizationsAsync.fulfilled, (state, action) => {
        state.organizations = action.payload;
      })
      .addCase(fetchCategoriesAsync.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(fetchTopLevelCategoriesAsync.fulfilled, (state, action) => {
        state.topLevelCategories = action.payload;
      })
      .addCase(fetchLicensesAsync.fulfilled, (state, action) => {
        state.licenses = action.payload;
      })
      .addCase(fetchTagsAsync.fulfilled, (state, action) => {
        state.tags = action.payload;
      })
      .addCase(fetchIsTaggedAsync.fulfilled, (state, action) => {
        state.isTagged = action.payload;
      })
      .addCase(fetchInCategoryAsync.fulfilled, (state, action) => {
        state.inCategory = action.payload;
      })
      .addCase(fetchHasLicenseAsync.fulfilled, (state, action) => {
        state.hasLicense = action.payload;
      });
  },
});

export const { resetState, updateConsumeData } = moduleSlice.actions;

export type ModuleSlice = {
  [moduleSlice.name]: ReturnType<(typeof moduleSlice)['reducer']>;
};

export const selectModules = (state: { module: ModuleState }) =>
  state.module.modules;
export const selectModuleById =
  (id: string) => (state: { module: ModuleState }) =>
    state.module.modules.find((module) => module.id === id);
export const selectOrganizations = (state: { module: ModuleState }) =>
  state.module.organizations;
export const selectOrganizationById =
  (id: string) => (state: { module: ModuleState }) =>
    state.module.organizations.find((organization) => organization.id === id);
export const selectOrganizationByModuleId =
  (id: string) => (state: { module: ModuleState }) => {
    const moduleAssociation = state.module.moduleAssociations.find((moduleAssociation) => moduleAssociation.in === id);
    return state.module.organizations.find((organization) => organization.id === moduleAssociation?.out);
  };
export const selectModuleAssociations = (state: { module: ModuleState }) =>
  state.module.moduleAssociations;
export const selectCategories = (state: { module: ModuleState }) =>
  state.module.categories;
export const selectLicenses = (state: { module: ModuleState }) =>
  state.module.licenses;
export const selectTags = (state: { module: ModuleState }) => state.module.tags;
export const selectIsTagged = (state: { module: ModuleState }) =>
  state.module.isTagged;
export const selectTagsByModuleId = (id: string) => (state: { module: ModuleState }) => {
  const tagged = state.module.isTagged.filter((tag) => tag.in === id);
  return state.module.tags.filter((tag) => tagged.some((t) => t.out === tag.id));
};
export const selectInCategory = (state: { module: ModuleState }) =>
  state.module.inCategory;
export const selectCategoryByModuleId = (id: string) => (state: { module: ModuleState }) => {
  const inCategory = state.module.inCategory.filter((inCategory) => inCategory.in === id);
  const categories = state.module.categories.filter((category) => inCategory.find((s) => s.out === category.id));
  return categories[0];
}
export const selectLicensed = (state: { module: ModuleState }) =>
  state.module.hasLicense;
export const selectLicenseByModuleId = (id: string) => (state: { module: ModuleState }) => {
  const licensed = state.module.hasLicense.filter((hasLicense) => hasLicense.in === id);
  const licenses = state.module.licenses.filter((license) => licensed.find((l) => l.out === license.id));
  return licenses[0];
}
export const selectRates = (state: { module: ModuleState }) => state.module.rates;
