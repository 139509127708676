import { createSlice } from '@reduxjs/toolkit';
import {
  NodeOrganization,
  Node,
  NodeIsAssociatedWith,
} from '../../schema/node';
import { createAppAsyncThunk } from '../createAppAsyncThunk';
import { Auction, Bid, Consume, Rate, Rating, Win } from '../../schema/auction';

export interface NodeState {
  initialized: boolean;
  wins: Win[];
  consumes: Consume[];
  bids: Bid[];
  rates: Rate[];
  ratings: Rating[];
  auctions: Auction[];
  nodes: Node[];
  organizations: NodeOrganization[];
  nodeAssociations: NodeIsAssociatedWith[];
}

const initialState: NodeState = {
  initialized: false,
  wins: [],
  bids: [],
  rates: [],
  ratings: [],
  consumes: [],
  auctions: [],
  nodes: [],
  organizations: [],
  nodeAssociations: [],
};

//call bidAsync then verifyBidAsync in sequence
export const bidAndVerifyAsync = createAppAsyncThunk(
  'node/bidAndVerify',
  async (bidData: { auction_id: string; amount: number }, thunkAPI) => {
    await thunkAPI.dispatch(bidAsync(bidData));
    await thunkAPI.dispatch(verifyBidAsync({auction_id: bidData.auction_id}));
    await thunkAPI.dispatch(fetchWinForAuctionAsync({auction_id: bidData.auction_id}));
    await thunkAPI.dispatch(fetchAuctionAsync({auction_id: bidData.auction_id}));
  }
);

export const bidAsync = createAppAsyncThunk(
  'node/bid',
  async (bidData: { auction_id: string; amount: number }, thunkAPI) => {
    const result = await thunkAPI.extra.db.bid(bidData.auction_id, bidData.amount);
    return result;
  }
);

export const verifyBidAsync = createAppAsyncThunk(
  'node/verifyBid',
  async (bidData: { auction_id: string }, thunkAPI) => {
    const bid = await thunkAPI.extra.db.verifyBid(bidData.auction_id);
    return bid;
  }
);

export const fetchWinForAuctionAsync = createAppAsyncThunk(
  'node/fetchWinForAuction',
  async (auctionData: { auction_id: string }, thunkAPI) => {
    const win = await thunkAPI.extra.db.getWinForAuction(auctionData.auction_id);
    return win;
  }
);

export const fetchAuctionAsync = createAppAsyncThunk(
  'node/fetchAuction',
  async (auctionData: { auction_id: string }, thunkAPI) => {
    const auction = await thunkAPI.extra.db.getAuction(auctionData.auction_id);
    return auction;
  }
);

export const consumeAsync = createAppAsyncThunk(
  'node/consume',
  async (consumeData: { node_id: string, job_type: string, template_name: string, win_id: string; encodedRequest: string }, thunkAPI) => {
    const consume = await thunkAPI.extra.db.consume(consumeData.node_id,
      consumeData.job_type, consumeData.template_name, consumeData.win_id, consumeData.encodedRequest);
    return consume;
  }
);

// load all data in parallel
export const fetchAllNodeDataAsync = createAppAsyncThunk(
  'node/loadData',
  async (userData: { id: string }, thunkAPI) => {
    await Promise.all([
      thunkAPI.dispatch(fetchWinsForUserAsync({id: userData.id})),
      thunkAPI.dispatch(fetchBidsAsync()),
      thunkAPI.dispatch(fetchRatesAsync()),
      thunkAPI.dispatch(fetchRatingsAsync()),
      thunkAPI.dispatch(fetchAuctionsAsync()),
      thunkAPI.dispatch(fetchConsumesAsync()),
      thunkAPI.dispatch(fetchNodesAsync()),
      thunkAPI.dispatch(fetchNodeAssociationsAsync()),
      thunkAPI.dispatch(fetchOrganizationsAsync()),
    ]);
  }
);

export const fetchWinsForUserAsync = createAppAsyncThunk(
  'node/fetchWinsForUser',
  async (userData: { id: string }, thunkAPI) => {
    const wins = await thunkAPI.extra.db.getWinsForUser(userData.id);
    return wins;
  }
);

export const fetchBidsAsync = createAppAsyncThunk(
  'node/fetchBids',
  async (_, thunkAPI) => {
    const bids = await thunkAPI.extra.db.getBids();
    return bids;
  }
);

export const fetchRatesAsync = createAppAsyncThunk(
  'node/fetchRates',
  async (_, thunkAPI) => {
    const rates = await thunkAPI.extra.db.getRates();
    return rates;
  }
);

export const fetchRatingsAsync = createAppAsyncThunk(
  'node/fetchRatings',
  async (_, thunkAPI) => {
    const ratings = await thunkAPI.extra.db.getRatings();
    return ratings;
  }
);

export const fetchAuctionsAsync = createAppAsyncThunk(
  'node/fetchOpenAuctions',
  async (_, thunkAPI) => {
    const openAuctions = await thunkAPI.extra.db.getAuctions();
    return openAuctions;
  }
);

export const fetchConsumesAsync = createAppAsyncThunk(
  'node/fetchConsumes',
  async (_, thunkAPI) => {
    const consumes = await thunkAPI.extra.db.getConsumes();
    return consumes;
  }
);

export const fetchNodesAsync = createAppAsyncThunk(
  'node/fetchNodes',
  async (_, thunkAPI) => {
    const nodes = await thunkAPI.extra.db.getNodes();
    return nodes;
  }
);

export const fetchOrganizationsAsync = createAppAsyncThunk(
  'node/fetchOrganizations',
  async (_, thunkAPI) => {
    const organizations = await thunkAPI.extra.db.getOrganizations();
    return organizations;
  }
);

export const fetchNodeAssociationsAsync = createAppAsyncThunk(
  'node/fetchNodeAssociations',
  async (_, thunkAPI) => {
    const nodeAssociations = await thunkAPI.extra.db.getNodeOrganizations();
    return nodeAssociations;
  }
);

export const listenToConsumeUpdates = createAppAsyncThunk(
  'auth/listenToConsumeUpdates',
  async ( winData: { id: string; }, thunkAPI) => {
    const db = thunkAPI.extra.db;
    const query_id = await db.subscribeToConsume(winData.id, (x) => {
			if (x.action === 'UPDATE') {
        thunkAPI.dispatch(updateConsumeData(x.result));
      }
		});
    console.log('listenToConsumeUpdates', query_id);
    return query_id;
  }
);

export const killMessageStream = createAppAsyncThunk(
  'auth/killMessageStream',
  async (query_id: string, thunkAPI) => {
    const db = thunkAPI.extra.db;
    await db.kill(query_id);
    console.log('killMessageStream', query_id);
  }
);

export const nodeSlice = createSlice({
  name: 'node',
  initialState,
  reducers: {
    resetState: (state) => {
      state.initialized = false;
      state.wins = [];
      state.bids = [];
      state.rates = [];
      state.auctions = [];
      state.nodes = [];
      state.organizations = [];
      state.nodeAssociations = [];
    },
    updateConsumeData: (state, action) => {
      const consume = action.payload;
      const index = state.consumes.findIndex((c) => c.id === consume.id);
      if (index === -1) {
        state.consumes.push(consume);
      } else {
        state.consumes[index] = consume;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllNodeDataAsync.fulfilled, (state) => {
        state.initialized = true;
      })
      .addCase(fetchWinsForUserAsync.fulfilled, (state, action) => {
        state.wins = action.payload;
      })
      .addCase(fetchBidsAsync.fulfilled, (state, action) => {
        state.bids = action.payload;
      })
      .addCase(verifyBidAsync.fulfilled, (state, action) => {
        const bid = action.payload;
        const index = state.bids.findIndex((b) => b.id === bid.id);
        if (index === -1) {
          state.bids.push(bid);
        } else {
          state.bids[index] = bid;
        }
      })
      .addCase(fetchWinForAuctionAsync.fulfilled, (state, action) => {
        const win = action.payload;
        if (win !== undefined && win.id) {
          const index = state.wins.findIndex((w) => w.id === win.id);
          if (index === -1) {
            state.wins.push(win);
          } else {
            state.wins[index] = win;
          }
        }
      })
      .addCase(fetchAuctionAsync.fulfilled, (state, action) => {
        const auction = action.payload;
        if (auction !== undefined && auction.id) {
          const index = state.auctions.findIndex((a) => a.id === auction.id);
          if (index === -1) {
            state.auctions.push(auction);
          } else {
            state.auctions[index] = auction;
          }
        }
      })
      .addCase(consumeAsync.fulfilled, (state, action) => {
        const consume = action.payload;
        if (consume !== undefined && consume.id) {
          const index = state.consumes.findIndex((c) => c.id === consume.id);
          if (index === -1) {
            state.consumes.push(consume);
          } else {
            state.consumes[index] = consume;
          }
        }
      })
      .addCase(fetchRatesAsync.fulfilled, (state, action) => {
        state.rates = action.payload;
      })
      .addCase(fetchRatingsAsync.fulfilled, (state, action) => {
        state.ratings = action.payload;
      })
      .addCase(fetchAuctionsAsync.fulfilled, (state, action) => {
        state.auctions = action.payload;
      })
      .addCase(fetchConsumesAsync.fulfilled, (state, action) => {
        state.consumes = action.payload;
      })
      .addCase(fetchNodesAsync.fulfilled, (state, action) => {
        state.nodes = action.payload;
      })
      .addCase(fetchNodeAssociationsAsync.fulfilled, (state, action) => {
        state.nodeAssociations = action.payload;
      })
      .addCase(fetchOrganizationsAsync.fulfilled, (state, action) => {
        state.organizations = action.payload;
      })
  },
});

export const { resetState, updateConsumeData } = nodeSlice.actions;

export type NodeSlice = {
  [nodeSlice.name]: ReturnType<(typeof nodeSlice)['reducer']>;
};

export const selectNodes = (state: { node: NodeState }) =>
  state.node.nodes;
export const selectNodeById =
  (id: string) => (state: { node: NodeState }) =>
    state.node.nodes.find((node) => node.id === id);
export const selectOrganizations = (state: { node: NodeState }) =>
  state.node.organizations;
export const selectOrganizationById =
  (id: string) => (state: { node: NodeState }) =>
    state.node.organizations.find((organization) => organization.id === id);
export const selectOrganizationByNodeId =
  (id: string) => (state: { node: NodeState }) => {
    const nodeAssociation = state.node.nodeAssociations.find((nodeAssociation) => nodeAssociation.in === id);
    return state.node.organizations.find((organization) => organization.id === nodeAssociation?.out);
  };
export const selectNodeAssociations = (state: { node: NodeState }) =>
  state.node.nodeAssociations;
export const selectAuctionById = (id: string) => (state: { node: NodeState }) =>
  state.node.auctions.find((auction) => auction.id === id);
export const selectAuctions = (state: { node: NodeState }) =>
  state.node.auctions;
export const selectAuctionsByNodeId = (id: string) => (state: { node: NodeState }) =>
  state.node.auctions.filter((auction) => auction.node === id);
export const selectWins = (state: { node: NodeState }) => state.node.wins;
export const selectWinByAuctionId = (id: string) => (state: { node: NodeState }) =>
  state.node.wins.find((win) => win.out === id);
export const selectConsumesByWinId = (id: string) => (state: { node: NodeState }) =>
  state.node.consumes.filter((consume) => consume.auction_ref === id);
export const selectRates = (state: { node: NodeState }) => state.node.rates;
export const selectRateByAuctionId = (id: string) => (state: { node: NodeState }) => {
  const rates = state.node.rates.filter((rate) => rate.auction === id);
  return rates[0];
}
export const selectBids = (state: { node: NodeState }) => state.node.bids;