import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TimeAgo } from '../../components/TimeAgo';
import { selectUser } from '../auth/authSlice';
import {
  fetchAllNodeDataAsync,
  selectNodeAssociations,
  selectNodes,
  selectOrganizations,
} from './nodeSlice';

export function Nodes() {
  const nodes = useAppSelector(selectNodes);
  const organizations = useAppSelector(selectOrganizations);
  const allNodeAssociations = useAppSelector(selectNodeAssociations);
  const { initialized } = useAppSelector((state) => state.node);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!initialized && user) {
      dispatch(fetchAllNodeDataAsync({ id: user?.id ?? '' }));
    }
  }, [user, dispatch, initialized]);

  return (
    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ sm: 4, md: 12 }}>
      {nodes.map((node) => {
        const nodeAssociation = allNodeAssociations.find(
          (nodeAssociation) => nodeAssociation.in === node.id
        );
        const organizationDetails = organizations.find(
          (organization) => nodeAssociation?.out === organization.id
        );
        return (
          <Grid item sm={4} md={6} key={node.id}>
            <Card>
              <CardActionArea className='card-hover'>
                <Link component={RouterLink} to={`/node/${node.id}`}>
                  <CardContent>
                    <Box display='flex' alignItems='center' gap={2}>
                      <img
                        src={
                          "/logo-flame.png"
                        }
                        alt={
                          organizationDetails ? organizationDetails.name : ''
                        }
                        style={{
                          width: '30px',
                          height: '30px',
                          objectFit: 'contain',
                          borderRadius: '10%',
                        }}
                      />
                      <Typography variant='h5' component='div' color='text.secondary'>
                        {node.id}
                      </Typography>
                    </Box>
                    <Typography
                      variant='body2'
                      color='text.secondary'
                      marginTop={2}
                    >
                      <TimeAgo isoDate={node.updated} /> • 454 likes
                    </Typography>
                  </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
