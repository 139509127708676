import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Grid, IconButton, Paper, Snackbar, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TimeInFuture } from '../../components/TimeAgo';
import UserName from '../../components/UserName';
import CustomSnackbar, { SnackbarConfig } from '../../components/CustomSnackbar';
import { selectUser } from '../auth/authSlice';
import NodeComponent from './NodeComponent';
import {
  bidAndVerifyAsync,
  fetchAllNodeDataAsync,
  selectAuctionsByNodeId,
  selectNodeById,
  selectOrganizationByNodeId,
  selectWins,
} from './nodeSlice';
import {
  selectModules,
} from '../module/moduleSlice';
import { ThumbDownSharp, ThumbUpSharp, Close } from '@mui/icons-material';
import { Auction } from '../../schema/auction';

const Schema = z.object({
  auction_id: z.string().min(1, 'Please select an auction'),
  bid_amount: z
    .string()
    .refine((value) => !isNaN(parseFloat(value)), {
      message: 'Invalid input: Please enter a number',
    })
    .refine((value) => parseFloat(value) >= 0.01, {
      message: 'Please enter a bid amount greater than .01',
    }),
});

type FormSchema = z.infer<typeof Schema>;

export function Node() {
  const [selectedAuction, setSelectedAuction] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormSchema>({
    resolver: zodResolver(Schema),
  });
  const { nodeid } = useParams();
  const node = useAppSelector(selectNodeById(nodeid ?? ''));
  const modules = useAppSelector(selectModules);
  const wins = useAppSelector(selectWins);
  const { initialized } = useAppSelector((state) => state.node);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarConfig>({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (!initialized && user) {
      dispatch(fetchAllNodeDataAsync({ id: user?.id ?? '' }));
    }
  }, [user, dispatch, initialized]);

  const organization = useAppSelector(selectOrganizationByNodeId(node?.id ?? ''));
  // FIXME: https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
  const auctions = useAppSelector(selectAuctionsByNodeId(node?.id ?? ''));
  const bids = useAppSelector((state) => state.node.bids);

  const handleClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const handleAction = useCallback((message: any, auctionId: string) => {
    setSnackbarConfig({
      open: true,
      message: message,
      actionComponent: auctionId ? (
        <React.Fragment>
          <RouterLink to={`/auction/${auctionId}`} style={{ textDecoration: 'none' }}>
            <Button color="secondary" size="small">
              VIEW AUCTION
            </Button>
          </RouterLink>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <Close />
          </IconButton>
        </React.Fragment>
      ) : null,
    });
  }, []);

  const buyItNowHandler = useCallback(
    async (data: { auction_id: string; amount: number }) => {
      const bidResult = await dispatch(
        bidAndVerifyAsync({
          auction_id: data.auction_id,
          amount: data.amount,
        })
      );
      if (bidAndVerifyAsync.fulfilled.match(bidResult)) {
        handleAction('Buy It Now Successful!', data.auction_id);
      }
    },
    [dispatch, handleAction]
  );

  const bidHandler = useCallback(
    async (data: FormSchema) => {
      const bidResult = await dispatch(
        bidAndVerifyAsync({
          auction_id: data.auction_id,
          amount: parseFloat(data.bid_amount),
        })
      );
      if (bidAndVerifyAsync.fulfilled.match(bidResult)) {
        handleAction('Bid Successful!', data.auction_id);
      }
      setSelectedAuction(null);
      reset();
    },
    [dispatch, setSelectedAuction, reset, handleAction]
  );

  return (
    <>
    <Box>
      <NodeComponent
        node={node}
        organization={organization}
      />
      <Paper elevation={3} sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}>
        <Typography variant='h5' gutterBottom color='primary'>
          Plans
        </Typography>
        {/* TODO add sorting and filtering */}
        <Grid container spacing={3} sx={styles.sellerGrid}>
          {auctions.map((auction) => {
            const hammerTime = new Date(auction.hammer_time);
            const now = new Date();
            return (
              hammerTime > now && (
                <React.Fragment key={auction.id}>
                  <Grid item xs={6} sm={3}>
                    {"CPU Plan (LLM: Phi-2)"}
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    {auction.buy_it_now_price && (
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          buyItNowHandler({
                            auction_id: auction.id.toString(),
                            amount: auction.buy_it_now_price ?? 0,
                          });
                        }}
                      >
                        buy it now for ${auction.buy_it_now_price}
                      </Button>
                    )}
                  </Grid>
                </React.Fragment>
              )
            );
          })}
          {auctions.length === 0 && (
            <Grid item xs={12} sm={6}>
              No one is offering this module right now.
            </Grid>
          )}
        </Grid>
      </Paper>
      <Paper elevation={3} sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}>
        <Typography variant='h5' gutterBottom color='primary'>
          Purchases
        </Typography>
        <Grid container spacing={1} alignItems={'center'}>
        {wins.map((win) =>
          auctions.map((auction: Auction) => {
            return (
              win.out === auction.id && (
                <React.Fragment key={win.id}>
                  <Grid item xs={12} md={3}>
                    <Typography variant='body2'>
                      {"CPU Plan (LLM: Phi-2)"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography variant='body2'>
                      {new Date(
                        auction?.hammer_time ?? Date()
                      ).toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} sx={styles.amount}>
                    <Typography variant='body1'>{`$${win.amount}`}</Typography>
                  </Grid>
                </React.Fragment>
              )
            );
          })
        )}
      </Grid>
      </Paper>
      <Paper elevation={3} sx={{ padding: 2, marginTop: 2, marginBottom: 2 }}>
        <Typography variant='h5' gutterBottom color='primary'>
          Available Co-Ops
        </Typography>
        <Grid container spacing={1} alignItems={'center'}>
        {modules.map((module) =>
          auctions.map((auction: Auction) => {
            return (
              <React.Fragment key={module.id}>
                <Grid item xs={12} md={3}>
                  <Typography variant='body2'>
                    {module.author.split(":")[1]}/{module.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} sx={styles.view}>
                  <Button
                    component={RouterLink}
                    to={`/module/${module.id}`}
                  >
                    View Module
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} sx={styles.view}>
                  <Button
                    component={RouterLink}
                    to={`/auction/${auction.id}`}
                  >
                    Run Module
                  </Button>
                </Grid>
              </React.Fragment>
            );
          })
        )}
      </Grid>
      </Paper>
    </Box>
    <CustomSnackbar
        open={snackbarConfig.open}
        message={snackbarConfig.message}
        handleClose={handleClose}
        actionComponent={snackbarConfig.actionComponent}
      />
    </>
  );
}

/**
 * @type {import("@mui/material").SxProps}
 */

const styles = {
  sellerGrid: {
    mt: 2,
  },
  amount: {
    textAlign: 'right',
  },
  view: {
    textAlign: 'right',
  },
};
